const navbar = {
    calculator: 'Калькулятор',
    resources: 'Ресурсы',
    integration: 'Интеграция',
    industries: 'Отрасли',
    pharmaceuticals: 'Фармацевтика',
    fieldSales: 'Полевые продажи',
    fmcg: 'Товары повседневного спроса',
    service: 'Услуги',
    fieldOperations: 'Полевые операции',
    logisticsAndTransportation: 'Логистика и транспортировка',
    education: 'Образование',
    products: 'Продукты',
    pricing: 'Цены',
    attendanceManagement: 'Управление посещаемостью',
    fieldForceManagement: 'Управление полевыми силами',
    company: 'Компания',
    ourStory: 'Наша история',
    joinUs: 'Присоединяйтесь к нам',
    contactUs: 'Свяжитесь с нами',
    integration: 'Интеграция',
    becomeAPartner: 'Стать партнером',
    blog: 'Блог',
};
export default navbar;
