const salaryCalculator = {
    enterAnnualCTC: 'Masukkan CTC Tahunan',
    enterAnnualCTCPlaceholder: 'Masukkan CTC tahunan Anda dan tekan enter',
    component: 'Komponen',
    monthly: 'Bulanan',
    annual: 'Tahunan',
    grossSalary: 'Gaji Kotor',
    basicSalary: 'Gaji Pokok',
    hra: 'Tunjangan Perumahan (HRA)',
    specialAllowance: 'Tunjangan Khusus',
    employeeContribution: 'Kontribusi Karyawan',
    epf: 'Dana Tabungan Karyawan (EPF)',
    labourWelfareFund: 'Dana Kesejahteraan Buruh (LWF)',
    esic: 'Perusahaan Asuransi Kesejahteraan Karyawan (ESIC)',
    employerContribution: 'Kontribusi Pemberi Kerja',
    takeHomeSalary: 'Gaji Bersih',
};
export default salaryCalculator;
