const fnfCalculator = {
    title: 'Full and Final Settlement Calculator',
    basicSalary: 'Basic Salary (Required)',
    earnedLeaves: 'Earned Leaves (Required)',
    paydaysInMonth: 'Paydays in a Month (Required)',
    noticePeriodSalary: 'Notice Period Salary (Optional)',
    gratuityPay: 'Gratuity Pay (Optional)',
    bonus: 'Bonus (Optional)',
    deductions: 'Deductions (EPF, ESI, TAX) (Optional)',
    totalFinalSettlementAmount: 'Total Final Settlement Amount',
    enterBasicSalary: 'Enter Basic Salary',
    enterEarnedLeaves: 'Enter Earned Leaves',
    enterNumberOfPaydaysInMonth: 'Enter Number of Paydays in a Month',
    enterNoticePeriodSalary: 'Enter Notice Period Salary',
    enterGratuityPay: 'Enter Gratuity Pay',
    enterBonusAmount: 'Enter Bonus Amount',
    enterTotalDeductions: 'Enter Total Deductions',
};
export default fnfCalculator;
