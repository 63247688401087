const leaveEnhancementCalculator = {
    title: 'Калькулятор увеличения отпускных',
    basicSalaryDA: 'Основная зарплата + DA',
    enterBasicSalary: 'Введите основную зарплату + DA',
    enternoOfEarnedLeaves: 'Введите количество накопленных отпусков',
    noOfEarnedLeaves: 'Количество накопленных отпусков',
    particulars: 'Детали',
    amount: 'Сумма',
    lastDrawnBasicSalaryDA: 'Последняя полученная основная зарплата + DA',
    utilizedLeavesAfterretirement: 'Использованные отпуска после выхода на пенсию',
    leaveEnhancementReceived: 'Полученное увеличение отпускных',
};
export default leaveEnhancementCalculator;
