import homePage from './homePage';
import features from './features';
import industries from './industries';
import products from './products';
import navbar from './navbar';
import pricingPage from './pricingPage';
import pricing from './pricing';
import joinUsPage from './joinUsPage';
import footer from './footer';
import calculators from './calculators';
import indivisualCalculators from './indivisualCalculators';

const data = {
    footer,
    homePage,
    navbar,
    features,
    industries,
    products,
    pricingPage,
    pricing,
    joinUsPage,
    calculators,
    indivisualCalculators,
};

export default data;
