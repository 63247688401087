const leaveEnhancementCalculator = {
    title: 'حاسبة تعزيز الإجازات',
    basicSalaryDA: 'الراتب الأساسي + بدل المعيشة',
    enterBasicSalary: 'أدخل الراتب الأساسي + بدل المعيشة',
    enternoOfEarnedLeaves: 'أدخل عدد الإجازات المكتسبة',
    noOfEarnedLeaves: 'عدد الإجازات المكتسبة',
    particulars: 'التفاصيل',
    amount: 'المبلغ',
    lastDrawnBasicSalaryDA: 'آخر راتب أساسي تم سحبه + بدل المعيشة',
    utilizedLeavesAfterretirement: 'الإجازات المستخدمة بعد التقاعد',
    leaveEnhancementReceived: 'تعزيز الإجازات المستلم',
};
export default leaveEnhancementCalculator;
