const paymentSlip = {
    companyInformation: 'কোম্পানি তথ্য',
    companyAddress: 'কোম্পানি ঠিকানা',
    enterCompanyAddress: 'কোম্পানি ঠিকানা লিখুন',
    city: 'শহর',
    enterCity: 'শহরের নাম লিখুন',
    pincode: 'পিনকোড',
    enterPincode: 'পিনকোড লিখুন',
    country: 'দেশ',
    enterCountry: 'দেশের নাম লিখুন',
    month: 'মাস',
    year: 'বছর',
    employeeDetails: 'কর্মচারীর বিবরণ',
    employeeName: 'কর্মচারীর নাম',
    enterEmployeeName: 'কর্মচারীর নাম লিখুন',
    employeeId: 'কর্মচারীর আইডি',
    enterEmployeeId: 'কর্মচারীর আইডি লিখুন',
    dateOfJoining: 'যোগদানের তারিখ',
    paidDays: 'প্রদত্ত দিন',
    enterPaidDays: 'প্রদত্ত দিনের সংখ্যা লিখুন',
    department: 'বিভাগ',
    enterDepartment: 'বিভাগ লিখুন',
    designation: 'পদবী',
    enterDesignation: 'পদবী লিখুন',
    bankName: 'ব্যাংকের নাম',
    enterBankName: 'ব্যাংকের নাম লিখুন',
    accountNumber: 'অ্যাকাউন্ট নম্বর',
    enterAccountNumber: 'অ্যাকাউন্ট নম্বর লিখুন',
    panNumber: 'প্যান নম্বর',
    enterPanNumber: 'প্যান নম্বর লিখুন',
    salaryStructure: 'বেতন কাঠামো',
    basicSalary: 'মৌলিক বেতন',
    enterBasicSalary: 'মৌলিক বেতন লিখুন',
    providentFund: 'প্রভিডেন্ট ফান্ড',
    houseRentAllowance: 'বাড়ি ভাড়া ভাতা',
    tds: 'টিডিএস',
    medicalAllowance: 'মেডিকেল ভাতা',
    professionalTax: 'পেশাগত কর',
    otherAllowance: 'অন্যান্য ভাতা',
    esi: 'ইএসআই',
    enteresi: 'ইএসআই লিখুন',
    particulars: 'বিবরণ',
    amount: 'পরিমাণ',
    grossEarnings: 'মোট উপার্জন',
    totalDeductions: 'মোট কর্তন',
    netSalary: 'নেট বেতন',
};
export default paymentSlip;
