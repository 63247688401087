const salaryCalculator = {
    enterAnnualCTC: 'वार्षिक CTC दर्ज करें',
    enterAnnualCTCPlaceholder: 'अपना वार्षिक CTC दर्ज करें और एंटर दबाएं',
    component: 'घटक',
    monthly: 'मासिक',
    annual: 'वार्षिक',
    grossSalary: 'सकल वेतन',
    basicSalary: 'मूल वेतन',
    hra: 'मकान किराया भत्ता (HRA)',
    specialAllowance: 'विशेष भत्ता',
    employeeContribution: 'कर्मचारी का योगदान',
    epf: 'कर्मचारी भविष्य निधि (EPF)',
    labourWelfareFund: 'श्रम कल्याण निधि (LWF)',
    esic: 'कर्मचारी राज्य बीमा निगम (ESIC)',
    employerContribution: 'नियोक्ता का योगदान',
    takeHomeSalary: 'निवल वेतन',
};
export default salaryCalculator;
