const paymentSlip = {
    companyInformation: 'Informações da Empresa',
    companyAddress: 'Endereço da Empresa',
    enterCompanyAddress: 'Insira o Endereço da Empresa',
    city: 'Cidade',
    enterCity: 'Insira a Cidade',
    pincode: 'Código Postal',
    enterPincode: 'Insira o Código Postal',
    country: 'País',
    enterCountry: 'Insira o País',
    month: 'Mês',
    year: 'Ano',
    employeeDetails: 'Detalhes do Funcionário',
    employeeName: 'Nome do Funcionário',
    enterEmployeeName: 'Insira o Nome do Funcionário',
    employeeId: 'ID do Funcionário',
    enterEmployeeId: 'Insira o ID do Funcionário',
    dateOfJoining: 'Data de Admissão',
    paidDays: 'Dias Pagos',
    enterPaidDays: 'Insira os Dias Pagos',
    department: 'Departamento',
    enterDepartment: 'Insira o Departamento',
    designation: 'Designação',
    enterDesignation: 'Insira a Designação',
    bankName: 'Nome do Banco',
    enterBankName: 'Insira o Nome do Banco',
    accountNumber: 'Número da Conta',
    enterAccountNumber: 'Insira o Número da Conta',
    panNumber: 'Número PAN',
    enterPanNumber: 'Insira o Número PAN',
    salaryStructure: 'Estrutura Salarial',
    basicSalary: 'Salário Base',
    enterBasicSalary: 'Insira o Salário Base',
    providentFund: 'Fundo de Previdência',
    houseRentAllowance: 'Subsídio de Aluguel',
    tds: 'TDS',
    medicalAllowance: 'Subsídio Médico',
    professionalTax: 'Imposto Profissional',
    otherAllowance: 'Outros Subsídios',
    esi: 'ESI',
    enteresi: 'Insira o ESI',
    particulars: 'Detalhes',
    amount: 'Montante',
    grossEarnings: 'Ganhos Brutos',
    totalDeductions: 'Deduções Totais',
    netSalary: 'Salário Líquido',
};
export default paymentSlip;
