const paymentSlip = {
    companyInformation: 'معلومات الشركة',
    companyAddress: 'عنوان الشركة',
    enterCompanyAddress: 'أدخل عنوان الشركة',
    city: 'المدينة',
    enterCity: 'أدخل المدينة',
    pincode: 'الرمز البريدي',
    enterPincode: 'أدخل الرمز البريدي',
    country: 'الدولة',
    enterCountry: 'أدخل الدولة',
    month: 'الشهر',
    year: 'السنة',
    employeeDetails: 'تفاصيل الموظف',
    employeeName: 'اسم الموظف',
    enterEmployeeName: 'أدخل اسم الموظف',
    employeeId: 'معرف الموظف',
    enterEmployeeId: 'أدخل معرف الموظف',
    dateOfJoining: 'تاريخ الانضمام',
    paidDays: 'الأيام المدفوعة',
    enterPaidDays: 'أدخل الأيام المدفوعة',
    department: 'القسم',
    enterDepartment: 'أدخل القسم',
    designation: 'المسمى الوظيفي',
    enterDesignation: 'أدخل المسمى الوظيفي',
    bankName: 'اسم البنك',
    enterBankName: 'أدخل اسم البنك',
    accountNumber: 'رقم الحساب',
    enterAccountNumber: 'أدخل رقم الحساب',
    panNumber: 'رقم PAN',
    enterPanNumber: 'أدخل رقم PAN',
    salaryStructure: 'هيكل الراتب',
    basicSalary: 'الراتب الأساسي',
    enterBasicSalary: 'أدخل الراتب الأساسي',
    providentFund: 'صندوق الادخار',
    houseRentAllowance: 'بدل إيجار المنزل',
    tds: 'TDS',
    medicalAllowance: 'بدل طبي',
    professionalTax: 'الضريبة المهنية',
    otherAllowance: 'بدل آخر',
    esi: 'ESI',
    enteresi: 'أدخل ESI',
    particulars: 'التفاصيل',
    amount: 'المبلغ',
    grossEarnings: 'الدخل الإجمالي',
    totalDeductions: 'إجمالي الخصومات',
    netSalary: 'الراتب الصافي',
};
export default paymentSlip;
