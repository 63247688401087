const config = {
    API_URL: process.env.REACT_APP_API_URL,
    GOOGLE_MAP_API: process.env.REACT_APP_GOOGLEMAP_API,
    GOOGLE_MAP_KEY: process.env.REACT_APP_GOOGLEMAP_KEY,
    CLOUDFUNCTION_API: process.env.REACT_APP_CLOUDFUNCTION_API,
    OUTLOOK_API: process.env.REACT_APP_OUTLOOK,
    RECAPTCHA_API: process.env.REACT_APP_RECAPTCHA,
    HELLO_LEADS_API: process.env.REACT_APP_HELLO_LEADS_URL,
    HLS_KEY: process.env.REACT_APP_HLS_KEY,
    XEMAIL: process.env.REACT_APP_XEMAIL,
    COOKIE: process.env.REACT_APP_COOKIE,
    RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    QR_CODE_API: process.env.REACT_APP_QR_CODE,
};

export default config;
