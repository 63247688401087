const salaryCalculator = {
    enterAnnualCTC: 'বার্ষিক CTC লিখুন',
    enterAnnualCTCPlaceholder: 'আপনার বার্ষিক CTC লিখুন এবং এন্টার টিপুন',
    component: 'উপাদান',
    monthly: 'মাসিক',
    annual: 'বার্ষিক',
    grossSalary: 'মোট বেতন',
    basicSalary: 'মূল বেতন',
    hra: 'গৃহ ভাড়া ভাতা (HRA)',
    specialAllowance: 'বিশেষ ভাতা',
    employeeContribution: 'কর্মচারীর অবদান',
    epf: 'কর্মচারী প্রভিডেন্ট ফান্ড (EPF)',
    labourWelfareFund: 'শ্রমিক কল্যাণ তহবিল (LWF)',
    esic: 'কর্মচারী রাজ্য বীমা কর্পোরেশন (ESIC)',
    employerContribution: 'নিয়োগকর্তার অবদান',
    takeHomeSalary: 'নিট বেতন',
};
export default salaryCalculator;
