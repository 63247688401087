const calculators = {
    calculators: 'Калькуляторы',
    heroSubTitle:
        'Узнайте подробное распределение вашей CTC, такое как зарплата, сумма выходного пособия и минимальная заработная плата по городам с калькуляторами зарплаты.',
    allCalculators: 'Все калькуляторы',
    salaryCalculator: {
        title: 'Калькулятор зарплаты',
        subTitle: 'Калькулятор зарплаты на руки в Индии',
        description: 'Рассчитайте распределение вашей зарплаты на руки по вашему CTC',
    },
    gratuityCalculator: {
        title: 'Калькулятор выходного пособия',
        subTitle: 'Калькулятор выходного пособия в Индии',
        description:
            'Используйте калькулятор выходного пособия, чтобы узнать точную сумму пособия за несколько простых кликов!',
    },
    fnfCalculator: {
        title: 'Калькулятор полного и окончательного расчета',
        subTitle: 'Калькулятор расчета FnF в Индии',
        description: 'Используйте калькулятор FnF, чтобы узнать точную сумму расчета за несколько секунд.',
    },
    leaveEncashmentCalculator: {
        title: 'Калькулятор компенсации за неиспользованный отпуск',
        subTitle: 'Калькулятор компенсации за неиспользованный отпуск в Индии',
        description:
            'Используйте калькулятор компенсации за отпуск, чтобы рассчитать сумму, которую сотрудник должен получить от работодателя.',
    },
    ppfCalculator: {
        title: 'Калькулятор государственного пенсионного фонда (PPF)',
        subTitle: 'Калькулятор PPF в Индии',
        description:
            'Используйте калькулятор PPF, чтобы рассчитать сумму по вкладу и проценты, которые будут получены по вашему инвестиционному счету.',
    },
    salarySlipGenerator: {
        title: 'Генератор расчетных листков',
        subTitle: 'Калькулятор расчетных листков в Индии',
        description:
            'Создавайте свои расчетные листки мгновенно с помощью нашего бесплатного онлайн-генератора и получайте финансовую ясность в пути.',
    },
};
export default calculators;
