const salaryCalculator = {
    enterAnnualCTC: 'ஆண்டு CTC-ஐ உள்ளிடவும்',
    enterAnnualCTCPlaceholder: 'உங்கள் ஆண்டு CTC-ஐ உள்ளிட்டு, எண்டரை அழுத்தவும்',
    component: 'கூறுகள்',
    monthly: 'மாதாந்திர',
    annual: 'ஆண்டாந்திர',
    grossSalary: 'மொத்த சம்பளம்',
    basicSalary: 'அடிப்படை சம்பளம்',
    hra: 'வீட்டு வாடகைத் தொகை (HRA)',
    specialAllowance: 'சிறப்பு கொடுப்பனவு',
    employeeContribution: 'ஊழியர் பங்களிப்பு',
    epf: 'ஊழியர் நலநிதி (EPF)',
    labourWelfareFund: 'தொழிலாளர் நல நிதி (LWF)',
    esic: 'ஊழியர் மாநில காப்பீட்டு கழகம் (ESIC)',
    employerContribution: 'தொழிலாளர் பங்களிப்பு',
    takeHomeSalary: 'கை சம்பளம்',
};
export default salaryCalculator;
