const ppfCalculator = {
    title: 'Calculadora PPF',
    yearlyInvestment: 'Inversión Anual:',
    enterYearlyInvestment: 'Ingrese la Inversión Anual',
    currentPPFInterestRate: 'Tasa de Interés Actual de PPF (%):',
    enterPPFInterestRate: 'Ingrese la Tasa de Interés de PPF',
    duration: 'Duración (en años):',
    enterDuration: 'Ingrese la Duración de la Inversión',
    particulars: 'Detalles',
    amount: 'Monto',
    investedAmount: 'Monto Invertido',
    totalInterest: 'Interés Total',
    ppfMaturity: 'Madurez de PPF',
};
export default ppfCalculator;
// Compare this snippet from src/pages/landings/resources/calculator/GratuityCalculator.jsx:
