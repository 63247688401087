const salaryCalculator = {
    enterAnnualCTC: 'વાર્ષિક CTC દાખલ કરો',
    enterAnnualCTCPlaceholder: 'તમારું વાર્ષિક CTC દાખલ કરો અને એન્ટર દબાવો',
    component: 'ઘટક',
    monthly: 'માસિક',
    annual: 'વાર્ષિક',
    grossSalary: 'કુલ પગાર',
    basicSalary: 'મૂળ પગાર',
    hra: 'હાઉસ રેન્ટ એલાઉન્સ (HRA)',
    specialAllowance: 'વિશેષ ભથ્થું',
    employeeContribution: 'કર્મચારીનું યોગદાન',
    epf: 'એમ્પ્લોયી પ્રોવિડન્ટ ફંડ (EPF)',
    labourWelfareFund: 'મજૂર કલ્યાણ ફંડ (LWF)',
    esic: 'એમ્પ્લોયી સ્ટેટ ઇન્સ્યોરન્સ કોર્પોરેશન (ESIC)',
    employerContribution: 'માલિકનું યોગદાન',
    takeHomeSalary: 'નેટ પગાર',
};
export default salaryCalculator;
