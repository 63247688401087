const fnfCalculator = {
    title: 'Calculadora de Liquidación Final Completa',
    basicSalary: 'Salario Base (Requerido)',
    earnedLeaves: 'Licencias Ganadas (Requerido)',
    paydaysInMonth: 'Días de Pago por Mes (Requerido)',
    noticePeriodSalary: 'Salario del Período de Aviso (Opcional)',
    gratuityPay: 'Pago de Gratificación (Opcional)',
    bonus: 'Bono (Opcional)',
    deductions: 'Deducciones (EPF, ESI, Impuestos) (Opcional)',
    totalFinalSettlementAmount: 'Monto Total de la Liquidación Final',
    enterBasicSalary: 'Ingrese el Salario Base',
    enterEarnedLeaves: 'Ingrese las Licencias Ganadas',
    enterNumberOfPaydaysInMonth: 'Ingrese el Número de Días de Pago por Mes',
    enterNoticePeriodSalary: 'Ingrese el Salario del Período de Aviso',
    enterGratuityPay: 'Ingrese el Pago de Gratificación',
    enterBonusAmount: 'Ingrese el Monto del Bono',
    enterTotalDeductions: 'Ingrese el Total de Deducciones',
};
export default fnfCalculator;
