const salaryCalculator = {
    enterAnnualCTC: 'Введите годовой CTC',
    enterAnnualCTCPlaceholder: 'Введите ваш годовой CTC и нажмите Enter',
    component: 'Компонент',
    monthly: 'Ежемесячно',
    annual: 'Ежегодно',
    grossSalary: 'Валовая зарплата',
    basicSalary: 'Основная зарплата',
    hra: 'Жилищное пособие (HRA)',
    specialAllowance: 'Специальная надбавка',
    employeeContribution: 'Взнос сотрудника',
    epf: 'Пенсионный фонд сотрудника (EPF)',
    labourWelfareFund: 'Фонд социального обеспечения рабочих (LWF)',
    esic: 'Государственная страховая корпорация сотрудников (ESIC)',
    employerContribution: 'Взнос работодателя',
    takeHomeSalary: 'Чистая зарплата',
};
export default salaryCalculator;
