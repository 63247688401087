const ppfCalculator = {
    title: 'PPF Calculator',
    yearlyInvestment: 'Yearly Investment:',
    enterYearlyInvestment: 'Enter Yearly Investment',
    currentPPFInterestRate: 'Current PPF Interest Rate (%):',
    enterPPFInterestRate: 'Enter PPF Interest Rate',
    duration: 'Duration (in years):',
    enterDuration: 'Enter Duration of Investment',
    partiCulars: 'Particulars',
    amount: 'Amount',
    investedAmount: 'Invested Amount',
    totalInterest: 'Total Interest',
    ppfMaturity: 'PPF Maturity',
};
export default ppfCalculator;
// Compare this snippet from src/pages/landings/resources/calculator/PPFCalculator.jsx:
