const fnfCalculator = {
    title: 'Калькулятор полного и окончательного расчета',
    basicSalary: 'Основная зарплата (обязательно)',
    earnedLeaves: 'Заработанные отпуска (обязательно)',
    paydaysInMonth: 'Дни выплаты в месяц (обязательно)',
    noticePeriodSalary: 'Зарплата за период уведомления (необязательно)',
    gratuityPay: 'Пенсионные выплаты (необязательно)',
    bonus: 'Бонус (необязательно)',
    deductions: 'Вычеты (EPF, ESI, налог) (необязательно)',
    totalFinalSettlementAmount: 'Общая сумма окончательного расчета',
    enterBasicSalary: 'Введите основную зарплату',
    enterEarnedLeaves: 'Введите количество заработанных отпусков',
    enterNumberOfPaydaysInMonth: 'Введите количество дней выплаты в месяц',
    enterNoticePeriodSalary: 'Введите зарплату за период уведомления',
    enterGratuityPay: 'Введите пенсионные выплаты',
    enterBonusAmount: 'Введите сумму бонуса',
    enterTotalDeductions: 'Введите общую сумму вычетов',
};
export default fnfCalculator;
