const paymentSlip = {
    companyInformation: 'நிறுவன தகவல்',
    companyAddress: 'நிறுவனத்தின் முகவரி',
    enterCompanyAddress: 'நிறுவனத்தின் முகவரியை உள்ளிடவும்',
    city: 'நகரம்',
    enterCity: 'நகரத்தை உள்ளிடவும்',
    pincode: 'அஞ்சல் குறியீடு',
    enterPincode: 'அஞ்சல் குறியீட்டை உள்ளிடவும்',
    country: 'நாடு',
    enterCountry: 'நாட்டை உள்ளிடவும்',
    month: 'மாதம்',
    year: 'வருடம்',
    employeeDetails: 'பணியாளர் விவரங்கள்',
    employeeName: 'பணியாளரின் பெயர்',
    enterEmployeeName: 'பணியாளரின் பெயரை உள்ளிடவும்',
    employeeId: 'பணியாளர் ஐடி',
    enterEmployeeId: 'பணியாளர் ஐடியை உள்ளிடவும்',
    dateOfJoining: 'சேர்ந்த தேதி',
    paidDays: 'கூலி பெறப்பட்ட நாட்கள்',
    enterPaidDays: 'கூலி பெறப்பட்ட நாட்களை உள்ளிடவும்',
    department: 'துறை',
    enterDepartment: 'துறையை உள்ளிடவும்',
    designation: 'பதவி',
    enterDesignation: 'பதவியை உள்ளிடவும்',
    bankName: 'வங்கியின் பெயர்',
    enterBankName: 'வங்கியின் பெயரை உள்ளிடவும்',
    accountNumber: 'வங்கி கணக்கு எண்',
    enterAccountNumber: 'வங்கி கணக்கு எண்ணை உள்ளிடவும்',
    panNumber: 'பான் எண்',
    enterPanNumber: 'பான் எண்ணை உள்ளிடவும்',
    salaryStructure: 'சம்பள அமைப்பு',
    basicSalary: 'அடிப்படை சம்பளம்',
    enterBasicSalary: 'அடிப்படை சம்பளத்தை உள்ளிடவும்',
    providentFund: 'நிலையத் தொகை',
    houseRentAllowance: 'வீட்டு வாடகைத் தள்ளுபடி',
    tds: 'TDS',
    medicalAllowance: 'மருத்துவ கொடுப்பனவு',
    professionalTax: 'தொழில் வரி',
    otherAllowance: 'மற்ற கொடுப்பனவுகள்',
    esi: 'ESI',
    enteresi: 'ESI-ஐ உள்ளிடவும்',
    particulars: 'விவரங்கள்',
    amount: 'தொகை',
    grossEarnings: 'மொத்த வருவாய்',
    totalDeductions: 'மொத்த கழிவுகள்',
    netSalary: 'நிகர சம்பளம்',
};
export default paymentSlip;
