const paymentSlip = {
    companyInformation: 'કંપની માહિતી',
    companyAddress: 'કંપનીનું સરનામું',
    enterCompanyAddress: 'કંપનીનું સરનામું દાખલ કરો',
    city: 'શહેર',
    enterCity: 'શહેર દાખલ કરો',
    pincode: 'પિનકોડ',
    enterPincode: 'પિનકોડ દાખલ કરો',
    country: 'દેશ',
    enterCountry: 'દેશ દાખલ કરો',
    month: 'મહિનો',
    year: 'વર્ષ',
    employeeDetails: 'કર્મચારી વિગતો',
    employeeName: 'કર્મચારીનું નામ',
    enterEmployeeName: 'કર્મચારીનું નામ દાખલ કરો',
    employeeId: 'કર્મચારી આઈડી',
    enterEmployeeId: 'કર્મચારી આઈડી દાખલ કરો',
    dateOfJoining: 'જોડાવાની તારીખ',
    paidDays: 'ચૂકવેલા દિવસો',
    enterPaidDays: 'ચૂકવેલા દિવસો દાખલ કરો',
    department: 'વિભાગ',
    enterDepartment: 'વિભાગ દાખલ કરો',
    designation: 'હોદ્દો',
    enterDesignation: 'હોદ્દો દાખલ કરો',
    bankName: 'બેન્કનું નામ',
    enterBankName: 'બેન્કનું નામ દાખલ કરો',
    accountNumber: 'ખાતું નંબર',
    enterAccountNumber: 'ખાતું નંબર દાખલ કરો',
    panNumber: 'PAN નંબર',
    enterPanNumber: 'PAN નંબર દાખલ કરો',
    salaryStructure: 'પગારની રચના',
    basicSalary: 'મૂળ પગાર',
    enterBasicSalary: 'મૂળ પગાર દાખલ કરો',
    providentFund: 'પ્રોવિડન્ટ ફંડ',
    houseRentAllowance: 'મકાન ભાડું ભથ્થું',
    tds: 'TDS',
    medicalAllowance: 'મેડિકલ ભથ્થું',
    professionalTax: 'વ્યવસાય કર',
    otherAllowance: 'અન્ય ભથ્થાં',
    esi: 'ESI',
    enteresi: 'ESI દાખલ કરો',
    particulars: 'વિશેષતા',
    amount: 'રકમ',
    grossEarnings: 'કુલ કમાણી',
    totalDeductions: 'કુલ કપાત',
    netSalary: 'શુદ્ધ પગાર',
};
export default paymentSlip;
