const leaveEnhancementCalculator = {
    title: "Calculateur d'Amélioration des Congés",
    basicSalaryDA: 'Salaire de base + DA',
    enterBasicSalary: 'Entrez le Salaire de base + DA',
    enternoOfEarnedLeaves: 'Entrez le nombre de congés accumulés',
    noOfEarnedLeaves: 'Nombre de congés accumulés',
    particulars: 'Détails',
    amount: 'Montant',
    lastDrawnBasicSalaryDA: 'Dernier Salaire de base + DA reçu',
    utilizedLeavesAfterretirement: 'Congés utilisés après la retraite',
    leaveEnhancementReceived: 'Amélioration des congés reçue',
};
export default leaveEnhancementCalculator;
