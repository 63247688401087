const salaryCalculator = {
    enterAnnualCTC: 'Enter Annual CTC',
    enterAnnualCTCPlaceholder: 'Enter your annual CTC and press enter',
    component: 'Component',
    monthly: 'Monthly',
    annual: 'Annual',
    grossSalary: 'Gross Salary',
    basicSalary: 'Basic Salary',
    hra: 'HRA',
    specialAllowance: 'Special Allowance',
    employeeContribution: 'Employee Contribution',
    epf: 'EPF',
    labourWelfareFund: 'Labour Welfare Fund (LWF)',
    esic: 'ESIC',
    employerContribution: 'Employer Contribution',
    takeHomeSalary: 'Take Home Salary',
};
export default salaryCalculator;
