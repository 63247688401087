const ppfCalculator = {
    title: 'Calculateur PPF',
    yearlyInvestment: 'Investissement Annuel :',
    enterYearlyInvestment: "Entrez l'Investissement Annuel",
    currentPPFInterestRate: "Taux d'Intérêt Actuel PPF (%):",
    enterPPFInterestRate: "Entrez le Taux d'Intérêt PPF",
    duration: 'Durée (en années) :',
    enterDuration: "Entrez la Durée de l'Investissement",
    particulars: 'Détails',
    amount: 'Montant',
    investedAmount: 'Montant Investi',
    totalInterest: 'Intérêt Total',
    ppfMaturity: 'Échéance PPF',
};
export default ppfCalculator;
// Compare this snippet from src/pages/landings/resources/calculator/GratuityCalculator.jsx:
