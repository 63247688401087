const navbar = {
    calculator: 'કૅલ્ક્યુલેટર',
    resources: 'સંસાધનો',
    integration: 'એકીકરણ',
    industries: 'ઉદ્યોગો',
    pharmaceuticals: 'ફાર્માસ્યુટિકલ્સ',
    fieldSales: 'ફીલ્ડ-સેલ્સ',
    fmcg: 'એફએમસીજી',
    service: 'સેવા',
    fieldOperations: 'ફીલ્ડ ઓપરેશન્સ',
    logisticsAndTransportation: 'લોજિસ્ટિક્સ અને પરિવહન',
    education: 'શિક્ષણ',
    products: 'ઉત્પાદનો',
    pricing: 'મુલ્ય',
    attendanceManagement: 'હાજરી વ્યવસ્થાપન',
    fieldForceManagement: 'ફીલ્ડ ફોર્સ મેનેજમેન્ટ',
    company: 'કંપની',
    ourStory: 'અમારી કહાણી',
    joinUs: 'અમારા સાથે જોડાઓ',
    contactUs: 'સંપર્ક કરો',
    integration: 'ઇન્ટિગ્રેશન',
    becomeAPartner: 'સાથી બનો',
    blog: 'બ્લોગ',
};
export default navbar;
