const fnfCalculator = {
    title: 'Calculadora de Liquidação Final e Completa',
    basicSalary: 'Salário Base (Obrigatório)',
    earnedLeaves: 'Férias Acumuladas (Obrigatório)',
    paydaysInMonth: 'Dias de Pagamento por Mês (Obrigatório)',
    noticePeriodSalary: 'Salário do Período de Aviso (Opcional)',
    gratuityPay: 'Pagamento de Gratificação (Opcional)',
    bonus: 'Bônus (Opcional)',
    deductions: 'Deduções (EPF, ESI, Impostos) (Opcional)',
    totalFinalSettlementAmount: 'Valor Total da Liquidação Final',
    enterBasicSalary: 'Digite o Salário Base',
    enterEarnedLeaves: 'Digite as Férias Acumuladas',
    enterNumberOfPaydaysInMonth: 'Digite o Número de Dias de Pagamento por Mês',
    enterNoticePeriodSalary: 'Digite o Salário do Período de Aviso',
    enterGratuityPay: 'Digite o Pagamento de Gratificação',
    enterBonusAmount: 'Digite o Valor do Bônus',
    enterTotalDeductions: 'Digite o Total das Deduções',
};
export default fnfCalculator;
