const calculators = {
    calculators: 'ক্যালকুলেটর',
    heroSubTitle:
        'স্যালারি ক্যালকুলেটরের মাধ্যমে আপনার CTC-এর বিশদ বিবরণ জানুন, যেমন বেতন, গ্রাচুইটি পরিমাণ এবং শহর ভিত্তিক ন্যূনতম মজুরি।',
    allCalculators: 'সমস্ত ক্যালকুলেটর',
    salaryCalculator: {
        title: 'বেতন ক্যালকুলেটর',
        subTitle: 'ভারতের ইন-হ্যান্ড বেতন ক্যালকুলেটর',
        description: 'আপনার CTC-এর ইন-হ্যান্ড বেতন ব্রেকআপ হিসাব করুন',
    },
    gratuityCalculator: {
        title: 'গ্রাচুইটি ক্যালকুলেটর',
        subTitle: 'ভারতের গ্রাচুইটি ক্যালকুলেটর',
        description: 'গ্রাচুইটি ক্যালকুলেটর ব্যবহার করুন এবং কয়েকটি সহজ ক্লিকে সঠিক গ্রাচুইটি পরিমাণ জানুন!',
    },
    fnfCalculator: {
        title: 'ফুল অ্যান্ড ফাইনাল ক্যালকুলেটর',
        subTitle: 'ভারতের FnF সেটেলমেন্ট ক্যালকুলেটর',
        description: 'FnF ক্যালকুলেটর ব্যবহার করুন এবং সেকেন্ডের মধ্যে সঠিক সেটেলমেন্ট পরিমাণ জানুন।',
    },
    leaveEncashmentCalculator: {
        title: 'ছুটি নগদীকরণ ক্যালকুলেটর',
        subTitle: 'ভারতের ছুটি নগদীকরণ ক্যালকুলেটর',
        description:
            'ছুটি নগদীকরণ ক্যালকুলেটর ব্যবহার করুন এবং জানুন একটি কর্মচারী কত টাকা তার নিয়োগকর্তার কাছ থেকে ছুটি নগদীকরণের হিসেবে পাবে।',
    },
    ppfCalculator: {
        title: 'পাবলিক প্রোভিডেন্ট ফান্ড (PPF) ক্যালকুলেটর',
        subTitle: 'ভারতের PPF ক্যালকুলেটর',
        description:
            'PPF ক্যালকুলেটর ব্যবহার করুন এবং আপনার বিনিয়োগের উপর কত পরিমাণ মুনাফা এবং পরিপক্কতার পরিমাণ পাবেন তা হিসাব করুন।',
    },
    salarySlipGenerator: {
        title: 'বেতন স্লিপ জেনারেটর',
        subTitle: 'ভারতের বেতন স্লিপ ক্যালকুলেটর',
        description:
            'আমাদের ফ্রি অনলাইন বেতন স্লিপ জেনারেটর ব্যবহার করুন এবং সহজেই আপনার বেতন স্লিপ তৈরি করুন, আর্থিক স্বচ্ছতা পান।',
    },
};
export default calculators;
