const leaveEnhancementCalculator = {
    title: 'अवकाश संवर्धन कैलकुलेटर',
    basicSalaryDA: 'मूल वेतन + DA',
    enterBasicSalary: 'मूल वेतन + DA दर्ज करें',
    enternoOfEarnedLeaves: 'अर्जित अवकाश की संख्या दर्ज करें',
    noOfEarnedLeaves: 'अर्जित अवकाश की संख्या',
    particulars: 'विवरण',
    amount: 'राशि',
    lastDrawnBasicSalaryDA: 'अंतिम प्राप्त मूल वेतन + DA',
    utilizedLeavesAfterretirement: 'सेवानिवृत्ति के बाद उपयोग किए गए अवकाश',
    leaveEnhancementReceived: 'प्राप्त अवकाश संवर्धन',
};
export default leaveEnhancementCalculator;
