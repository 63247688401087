const leaveEnhancementCalculator = {
    title: 'Calculadora de Melhoria de Férias',
    basicSalaryDA: 'Salário Base + DA',
    enterBasicSalary: 'Digite o Salário Base + DA',
    enternoOfEarnedLeaves: 'Digite o número de Férias Acumuladas',
    noOfEarnedLeaves: 'Número de Férias Acumuladas',
    particulars: 'Detalhes',
    amount: 'Valor',
    lastDrawnBasicSalaryDA: 'Último Salário Base + DA Recebido',
    utilizedLeavesAfterretirement: 'Férias Utilizadas Após Aposentadoria',
    leaveEnhancementReceived: 'Melhoria de Férias Recebida',
};
export default leaveEnhancementCalculator;
