const leaveEnhancementCalculator = {
    title: 'Kalkulator Peningkatan Cuti',
    basicSalaryDA: 'Gaji Pokok + DA',
    enterBasicSalary: 'Masukkan Gaji Pokok + DA',
    enternoOfEarnedLeaves: 'Masukkan Jumlah Cuti yang Diperoleh',
    noOfEarnedLeaves: 'Jumlah Cuti yang Diperoleh',
    particulars: 'Rincian',
    amount: 'Jumlah',
    lastDrawnBasicSalaryDA: 'Gaji Pokok + DA Terakhir yang Ditarik',
    utilizedLeavesAfterretirement: 'Cuti yang Digunakan Setelah Pensiun',
    leaveEnhancementReceived: 'Peningkatan Cuti yang Diterima',
};
export default leaveEnhancementCalculator;
