import { BrowserRouter } from 'react-router-dom';

// routes
import AllRoutes from '.';
import QRModal from 'components/QRModal';

const Routes = () => {
    return (
        <BrowserRouter>
            <AllRoutes />
            <QRModal />
        </BrowserRouter>
    );
};

export default Routes;
