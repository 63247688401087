const ppfCalculator = {
    title: 'PPF कैलकुलेटर',
    yearlyInvestment: 'वार्षिक निवेश:',
    enterYearlyInvestment: 'वार्षिक निवेश दर्ज करें',
    currentPPFInterestRate: 'वर्तमान PPF ब्याज दर (%):',
    enterPPFInterestRate: 'PPF ब्याज दर दर्ज करें',
    duration: 'अवधि (वर्षों में):',
    enterDuration: 'निवेश की अवधि दर्ज करें',
    particulars: 'विवरण',
    amount: 'राशि',
    investedAmount: 'निवेश की गई राशि',
    totalInterest: 'कुल ब्याज',
    ppfMaturity: 'PPF परिपक्वता',
};
export default ppfCalculator;
// Compare this snippet from src/pages/landings/resources/calculator/GratuityCalculator.jsx:
