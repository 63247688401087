const fnfCalculator = {
    title: 'Kalkulator Penyelesaian Akhir dan Lengkap',
    basicSalary: 'Gaji Pokok (Wajib)',
    earnedLeaves: 'Cuti yang Diperoleh (Wajib)',
    paydaysInMonth: 'Hari Gajian dalam Sebulan (Wajib)',
    noticePeriodSalary: 'Gaji Periode Pemberitahuan (Opsional)',
    gratuityPay: 'Pembayaran Gratifikasi (Opsional)',
    bonus: 'Bonus (Opsional)',
    deductions: 'Potongan (EPF, ESI, Pajak) (Opsional)',
    totalFinalSettlementAmount: 'Jumlah Penyelesaian Akhir Total',
    enterBasicSalary: 'Masukkan Gaji Pokok',
    enterEarnedLeaves: 'Masukkan Cuti yang Diperoleh',
    enterNumberOfPaydaysInMonth: 'Masukkan Jumlah Hari Gajian dalam Sebulan',
    enterNoticePeriodSalary: 'Masukkan Gaji Periode Pemberitahuan',
    enterGratuityPay: 'Masukkan Pembayaran Gratifikasi',
    enterBonusAmount: 'Masukkan Jumlah Bonus',
    enterTotalDeductions: 'Masukkan Jumlah Potongan',
};
export default fnfCalculator;
