const ppfCalculator = {
    title: 'حاسبة PPF',
    yearlyInvestment: 'الاستثمار السنوي',
    enterYearlyInvestment: 'أدخل الاستثمار السنوي',
    currentPPFInterestRate: 'نسبة الفائدة الحالية لـ PPF (%):',
    enterPPFInterestRate: 'أدخل نسبة الفائدة لـ PPF',
    duration: 'المدة (بالسنوات):',
    enterDuration: 'أدخل مدة الاستثمار',
    particulars: 'التفاصيل',
    amount: 'المبلغ',
    investedAmount: 'المبلغ المستثمر',
    totalInterest: 'إجمالي الفائدة',
    ppfMaturity: 'استحقاق PPF',
};
export default ppfCalculator;
// Compare this snippet from src/pages/landings/resources/calculator/GratuityCalculator.jsx:
