const fnfCalculator = {
    title: 'ফুল অ্যান্ড ফাইনাল সেটেলমেন্ট ক্যালকুলেটর',
    basicSalary: 'বেসিক বেতন (আবশ্যক)',
    earnedLeaves: 'অর্জিত ছুটি (আবশ্যক)',
    paydaysInMonth: 'প্রতি মাসে পে ডে (আবশ্যক)',
    noticePeriodSalary: 'নোটিশ পিরিয়ড বেতন (ঐচ্ছিক)',
    gratuityPay: 'গ্রাচুইটি পে (ঐচ্ছিক)',
    bonus: 'বোনাস (ঐচ্ছিক)',
    deductions: 'কাটছাঁট (EPF, ESI, ট্যাক্স) (ঐচ্ছিক)',
    totalFinalSettlementAmount: 'মোট চূড়ান্ত সেটেলমেন্ট পরিমাণ',
    enterBasicSalary: 'বেসিক বেতন প্রবেশ করুন',
    enterEarnedLeaves: 'অর্জিত ছুটি প্রবেশ করুন',
    enterNumberOfPaydaysInMonth: 'প্রতি মাসে পে ডে এর সংখ্যা প্রবেশ করুন',
    enterNoticePeriodSalary: 'নোটিশ পিরিয়ড বেতন প্রবেশ করুন',
    enterGratuityPay: 'গ্রাচুইটি পে প্রবেশ করুন',
    enterBonusAmount: 'বোনাস পরিমাণ প্রবেশ করুন',
    enterTotalDeductions: 'মোট কাটছাঁট প্রবেশ করুন',
};
export default fnfCalculator;
