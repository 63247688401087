const salaryCalculator = {
    enterAnnualCTC: 'Entrez le CTC annuel',
    enterAnnualCTCPlaceholder: 'Entrez votre CTC annuel et appuyez sur Entrée',
    component: 'Composant',
    monthly: 'Mensuel',
    annual: 'Annuel',
    grossSalary: 'Salaire brut',
    basicSalary: 'Salaire de base',
    hra: 'Indemnité de logement (HRA)',
    specialAllowance: 'Allocation spéciale',
    employeeContribution: "Contribution de l'employé",
    epf: 'Fonds de prévoyance des employés (EPF)',
    labourWelfareFund: 'Fonds de bien-être des travailleurs (LWF)',
    esic: "Société d'assurance sociale des employés (ESIC)",
    employerContribution: "Contribution de l'employeur",
    takeHomeSalary: 'Salaire net',
};
export default salaryCalculator;
