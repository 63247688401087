const leaveEnhancementCalculator = {
    title: 'અવકાશ વધારો ગણતરીકર્તા',
    basicSalaryDA: 'મૂળ પગાર + DA',
    enterBasicSalary: 'મૂળ પગાર + DA દાખલ કરો',
    enternoOfEarnedLeaves: 'અર્જિત છૂટીઓની સંખ્યા દાખલ કરો',
    noOfEarnedLeaves: 'અર્જિત છૂટીઓની સંખ્યા',
    particulars: 'વિશેષતા',
    amount: 'રકમ',
    lastDrawnBasicSalaryDA: 'છેલ્લી વખત મેળવેલ મૂળ પગાર + DA',
    utilizedLeavesAfterretirement: 'પેન્શન બાદ ઉપયોગ કરેલી છૂટીઓ',
    leaveEnhancementReceived: 'પ્રાપ્ત છુટીઓ વધારણી',
};
export default leaveEnhancementCalculator;
