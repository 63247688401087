const salaryCalculator = {
    enterAnnualCTC: 'Ingrese el CTC Anual',
    enterAnnualCTCPlaceholder: 'Ingrese su CTC anual y presione Enter',
    component: 'Componente',
    monthly: 'Mensual',
    annual: 'Anual',
    grossSalary: 'Salario Bruto',
    basicSalary: 'Salario Básico',
    hra: 'Subsidio de Vivienda (HRA)',
    specialAllowance: 'Subsidio Especial',
    employeeContribution: 'Contribución del Empleado',
    epf: 'Fondo de Previsión para Empleados (EPF)',
    labourWelfareFund: 'Fondo de Bienestar Laboral (LWF)',
    esic: 'Corporación de Seguro Social para Empleados (ESIC)',
    employerContribution: 'Contribución del Empleador',
    takeHomeSalary: 'Salario Neto',
};
export default salaryCalculator;
