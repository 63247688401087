const calculators = {
    calculators: 'கணக்கீடுகள்',
    heroSubTitle:
        'சம்பளக் கணக்கீடுகளுடன் உங்கள் CTC இன் விரிவான பிரிவு அறிக, உதாரணமாக சம்பளம், பரிசுத்தொகை தொகை மற்றும் நகரத்திற்கு மாற்றான குறைந்தபட்ச ஊதியம்.',
    allCalculators: 'அனைத்து கணக்கீடுகள்',
    salaryCalculator: {
        title: 'சம்பளக் கணக்கீடுகள்',
        subTitle: 'இந்தியாவில் உள்ள மொத்த சம்பளக் கணக்கீடுகள்',
        description: 'உங்கள் CTC இன் மூலம் உங்கள் கையொப்ப சம்பளத்தை கணக்கிடுங்கள்',
    },
    gratuityCalculator: {
        title: 'பரிசுத்தொகை கணக்கீடுகள்',
        subTitle: 'இந்தியாவில் பரிசுத்தொகை கணக்கீடுகள்',
        description: 'சில எளிய கிளிக் மூலம் சரியான பரிசுத்தொகை தொகையை அறிய பரிசுத்தொகை கணக்கீடுகளை பயன்படுத்துங்கள்!',
    },
    fnfCalculator: {
        title: 'முழு மற்றும் இறுதிகால கணக்கீடுகள்',
        subTitle: 'இந்தியாவில் FnF தீர்வு கணக்கீடுகள்',
        description: 'FnF கணக்கீடுகளைப் பயன்படுத்தி சில விநாடிகளில் சரியான தீர்வு தொகையை அறியுங்கள்.',
    },
    leaveEncashmentCalculator: {
        title: 'விடுமுறை பணத்தொகை கணக்கீடுகள்',
        subTitle: 'இந்தியாவில் விடுமுறை பணத்தொகை கணக்கீடுகள்',
        description:
            'பணியாளர் தனது பணியாளர் நிறுவனத்திடமிருந்து பெற்றுக் கொள்ள வேண்டிய விடுமுறை பணத்தொகையை கணக்கிட இந்த விடுமுறை பணத்தொகை கணக்கீடுகளைப் பயன்படுத்துங்கள்.',
    },
    ppfCalculator: {
        title: 'பொது நலனான திட்டம் (PPF) கணக்கீடுகள்',
        subTitle: 'இந்தியாவில் PPF கணக்கீடுகள்',
        description:
            'PPF கணக்கீடுகளைப் பயன்படுத்தி PPF முடிவிலி தொகை மற்றும் அவர்களது முதலீட்டில் கிடைக்கும் வட்டி தொகையை கணக்கிடுங்கள்.',
    },
    salarySlipGenerator: {
        title: 'சம்பள அடையாளக் கடிதம் உருவாக்கி',
        subTitle: 'இந்தியாவில் சம்பள அடையாளக் கடிதம் கணக்கீடுகள்',
        description:
            'எங்கள் இலவச ஆன்லைன் சம்பள அடையாளக் கடித உருவாக்கி மூலம் உங்கள் சம்பள அடையாளக் கடிதங்களை உடனடியாக உருவாக்கி, நிதி தெளிவை பெற்றுக்கொள்ளுங்கள்.',
    },
};
export default calculators;
