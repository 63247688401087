const ppfCalculator = {
    title: 'PPF ગણતરીકર્તા',
    yearlyInvestment: 'વાર્ષિક રોકાણ:',
    enterYearlyInvestment: 'વાર્ષિક રોકાણ દાખલ કરો',
    currentPPFInterestRate: 'વર્તમાન PPF વ્યાજ દર (%):',
    enterPPFInterestRate: 'PPF વ્યાજ દર દાખલ કરો',
    duration: 'સમયગાળો (વર્ષોમાં):',
    enterDuration: 'રોકાણનો સમયગાળો દાખલ કરો',
    particulars: 'વિશેષતા',
    amount: 'રકમ',
    investedAmount: 'રોકાણ કરેલી રકમ',
    totalInterest: 'કુલ વ્યાજ',
    ppfMaturity: 'PPF પરિપક્વતા',
};
export default ppfCalculator;
// Compare this snippet from src/pages/landings/resources/calculator/GratuityCalculator.jsx:
