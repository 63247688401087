const ppfCalculator = {
    title: 'Calculadora PPF',
    yearlyInvestment: 'Investimento Anual:',
    enterYearlyInvestment: 'Insira o Investimento Anual',
    currentPPFInterestRate: 'Taxa de Juros Atual do PPF (%):',
    enterPPFInterestRate: 'Insira a Taxa de Juros do PPF',
    duration: 'Duração (em anos):',
    enterDuration: 'Insira a Duração do Investimento',
    particulars: 'Detalhes',
    amount: 'Montante',
    investedAmount: 'Montante Investido',
    totalInterest: 'Juros Totais',
    ppfMaturity: 'Vencimento do PPF',
};
export default ppfCalculator;
// Compare this snippet from src/pages/landings/resources/calculator/GratuityCalculator.jsx:
