const paymentSlip = {
    companyInformation: 'Información de la Empresa',
    companyAddress: 'Dirección de la Empresa',
    enterCompanyAddress: 'Ingrese la Dirección de la Empresa',
    city: 'Ciudad',
    enterCity: 'Ingrese la Ciudad',
    pincode: 'Código Postal',
    enterPincode: 'Ingrese el Código Postal',
    country: 'País',
    enterCountry: 'Ingrese el País',
    month: 'Mes',
    year: 'Año',
    employeeDetails: 'Detalles del Empleado',
    employeeName: 'Nombre del Empleado',
    enterEmployeeName: 'Ingrese el Nombre del Empleado',
    employeeId: 'ID del Empleado',
    enterEmployeeId: 'Ingrese el ID del Empleado',
    dateOfJoining: 'Fecha de Ingreso',
    paidDays: 'Días Pagados',
    enterPaidDays: 'Ingrese los Días Pagados',
    department: 'Departamento',
    enterDepartment: 'Ingrese el Departamento',
    designation: 'Designación',
    enterDesignation: 'Ingrese la Designación',
    bankName: 'Nombre del Banco',
    enterBankName: 'Ingrese el Nombre del Banco',
    accountNumber: 'Número de Cuenta',
    enterAccountNumber: 'Ingrese el Número de Cuenta',
    panNumber: 'Número PAN',
    enterPanNumber: 'Ingrese el Número PAN',
    salaryStructure: 'Estructura Salarial',
    basicSalary: 'Salario Básico',
    enterBasicSalary: 'Ingrese el Salario Básico',
    providentFund: 'Fondo de Previsión',
    houseRentAllowance: 'Subsidio de Alquiler',
    tds: 'TDS',
    medicalAllowance: 'Subsidio Médico',
    professionalTax: 'Impuesto Profesional',
    otherAllowance: 'Otros Subsidios',
    esi: 'ESI',
    enteresi: 'Ingrese el ESI',
    particulars: 'Detalles',
    amount: 'Monto',
    grossEarnings: 'Ganancias Brutas',
    totalDeductions: 'Deducciones Totales',
    netSalary: 'Salario Neto',
};
export default paymentSlip;
