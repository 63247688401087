const navbar = {
    calculator: 'Calculator',
    resources: 'Resources',
    integration: 'Integration',
    industries: 'Industries',
    pharmaceuticals: 'Pharmaceuticals',
    fieldSales: 'Field-Sales',
    fmcg: 'FMCG',
    service: 'Service',
    fieldOperations: 'Field Operations',
    logisticsAndTransportation: 'Logistics And Transportation',
    education: 'Education',
    products: 'Products',
    pricing: 'Pricing',
    attendanceManagement: 'Attendance Management',
    fieldForceManagement: 'Field Force Management',
    company: 'Company',
    ourStory: 'Our Story',
    joinUs: 'Join Us',
    contactUs: 'Contact Us',
    integration: 'Integration',
    becomeAPartner: 'Become a Partner',
    blog: 'Blog',
};

export default navbar;
