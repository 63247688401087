const leaveEnhancementCalculator = {
    title: 'விடுப்பு மேம்பாட்டு கணக்கிடுபவர்',
    basicSalaryDA: 'அடிப்படை சம்பளம் + DA',
    enterBasicSalary: 'அடிப்படை சம்பளம் + DA உள்ளிடவும்',
    enternoOfEarnedLeaves: 'உறுதிசெய்யப்பட்ட விடுப்பு நாட்களின் எண்ணிக்கையை உள்ளிடவும்',
    noOfEarnedLeaves: 'அர்ஜிக்கப்பட்ட விடுப்பு நாட்கள்',
    particulars: 'விவரங்கள்',
    amount: 'ராசி',
    lastDrawnBasicSalaryDA: 'கடைசியாக பெறப்பட்ட அடிப்படை சம்பளம் + DA',
    utilizedLeavesAfterretirement: 'பணியாற்றலைப் பிறகு பயன்படுத்திய விடுப்பு நாட்கள்',
    leaveEnhancementReceived: 'பொறுப்பேற்ற விடுப்பு மேம்பாடு',
};
export default leaveEnhancementCalculator;
