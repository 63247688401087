const fnfCalculator = {
    title: 'முழு மற்றும் இறுதிச்settlement கணக்கீட்டான்',
    basicSalary: 'அடிப்படை சம்பளம் (குறிப்பிடப்பட்டது)',
    earnedLeaves: 'வெற்றி விடுமுறை (குறிப்பிடப்பட்டது)',
    paydaysInMonth: 'ஒரு மாதத்தில் வேலைபாடுகள் (குறிப்பிடப்பட்டது)',
    noticePeriodSalary: 'அறிவிப்புக் கால சம்பளம் (விருப்பம்)',
    gratuityPay: 'நன்றி தொகை (விருப்பம்)',
    bonus: 'போனஸ் (விருப்பம்)',
    deductions: 'கழிப்புகள் (EPF, ESI, வரி) (விருப்பம்)',
    totalFinalSettlementAmount: 'மொத்த இறுதிச்settlement தொகை',
    enterBasicSalary: 'அடிப்படை சம்பளம் உள்ளிடவும்',
    enterEarnedLeaves: 'அர்த்தவாரி விடுமுறை உள்ளிடவும்',
    enterNumberOfPaydaysInMonth: 'ஒரு மாதத்தில் வேலைபாடுகள் எண்ணிக்கை உள்ளிடவும்',
    enterNoticePeriodSalary: 'அறிவிப்புக் கால சம்பளம் உள்ளிடவும்',
    enterGratuityPay: 'நன்றி தொகை உள்ளிடவும்',
    enterBonusAmount: 'போனஸ் தொகை உள்ளிடவும்',
    enterTotalDeductions: 'மொத்த கழிப்புகள் உள்ளிடவும்',
};
export default fnfCalculator;
