const calculators = {
    calculators: 'Calculators',
    heroSubTitle:
        'Know the detailed breakdown of your CTC like salary, gratuity amount, and city-wise minimum wages with salary calculators.',
    allCalculators: 'All Calculators',
    salaryCalculator: {
        title: 'Salary Calculator',
        subTitle: 'In-hand Salary Calculator in India',
        description: 'Calculate your in-hand salary breakup of your CTC',
    },
    gratuityCalculator: {
        title: 'Gratuity Calculator',
        subTitle: 'Gratuity Calculator India',
        description: 'Use the gratuity calculator to know the exact gratuity amount in a few simple clicks!',
    },
    fnfCalculator: {
        title: 'Full & Final Calculators',
        subTitle: 'FnF Settlement Calculator India',
        description: 'Use the FnF calculator to know the exact settlement amount in seconds.',
    },
    leaveEncashmentCalculator: {
        title: 'Leave Encashment Calculator',
        subTitle: 'Leave Encashment Calculator India',
        description:
            'Use the Leave Encashment Calculator to calculate leave encashment amount an employee has to receive from his employer.',
    },
    ppfCalculator: {
        title: 'Public Provident Fund (PPF) Calculator',
        subTitle: 'PPF Calculator India',
        description:
            'Use the PPF Calculator to calculate PPF maturity amount and interest they will receive on their investment.',
    },
    salarySlipGenerator: {
        title: 'Salary Slip Generator',
        subTitle: 'Salary Slip Calculator India',
        description:
            'Generate your payslips instantly with our free online salary slip generator and get financial clarity on-the-go.',
    },
};
export default calculators;
