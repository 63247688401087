const calculators = {
    calculators: 'Calculatrices',
    heroSubTitle:
        'Découvrez la répartition détaillée de votre CTC comme le salaire, le montant de la gratification, et les salaires minimaux selon les villes avec les calculatrices de salaire.',
    allCalculators: 'Toutes les calculatrices',
    salaryCalculator: {
        title: 'Calculatrice de salaire',
        subTitle: 'Calculatrice de salaire en main en Inde',
        description: 'Calculez la répartition de votre salaire en main à partir de votre CTC',
    },
    gratuityCalculator: {
        title: 'Calculatrice de gratification',
        subTitle: 'Calculatrice de gratification en Inde',
        description:
            'Utilisez la calculatrice de gratification pour connaître le montant exact de la gratification en quelques clics simples !',
    },
    fnfCalculator: {
        title: 'Calculatrices de règlement complet',
        subTitle: 'Calculatrice de règlement FnF en Inde',
        description: 'Utilisez la calculatrice FnF pour connaître le montant exact du règlement en quelques secondes.',
    },
    leaveEncashmentCalculator: {
        title: 'Calculatrice de liquidation des congés',
        subTitle: 'Calculatrice de liquidation des congés en Inde',
        description:
            "Utilisez la calculatrice de liquidation des congés pour calculer le montant que l'employé doit recevoir de son employeur pour ses congés.",
    },
    ppfCalculator: {
        title: 'Calculatrice du Fonds de Prévoyance Public (PPF)',
        subTitle: 'Calculatrice PPF en Inde',
        description:
            "Utilisez la calculatrice PPF pour calculer le montant de maturité du PPF et les intérêts qu'ils recevront sur leur investissement.",
    },
    salarySlipGenerator: {
        title: 'Générateur de fiche de paie',
        subTitle: 'Calculatrice de fiche de paie en Inde',
        description:
            'Générez instantanément vos fiches de paie avec notre générateur de fiche de paie en ligne gratuit et obtenez une clarté financière en déplacement.',
    },
};
export default calculators;
