const paymentSlip = {
    companyInformation: 'Informasi Perusahaan',
    companyAddress: 'Alamat Perusahaan',
    enterCompanyAddress: 'Masukkan Alamat Perusahaan',
    city: 'Kota',
    enterCity: 'Masukkan Kota',
    pincode: 'Kode Pos',
    enterPincode: 'Masukkan Kode Pos',
    country: 'Negara',
    enterCountry: 'Masukkan Negara',
    month: 'Bulan',
    year: 'Tahun',
    employeeDetails: 'Detail Karyawan',
    employeeName: 'Nama Karyawan',
    enterEmployeeName: 'Masukkan Nama Karyawan',
    employeeId: 'ID Karyawan',
    enterEmployeeId: 'Masukkan ID Karyawan',
    dateOfJoining: 'Tanggal Bergabung',
    paidDays: 'Hari Dibayar',
    enterPaidDays: 'Masukkan Hari Dibayar',
    department: 'Departemen',
    enterDepartment: 'Masukkan Departemen',
    designation: 'Jabatan',
    enterDesignation: 'Masukkan Jabatan',
    bankName: 'Nama Bank',
    enterBankName: 'Masukkan Nama Bank',
    accountNumber: 'Nomor Rekening',
    enterAccountNumber: 'Masukkan Nomor Rekening',
    panNumber: 'Nomor PAN',
    enterPanNumber: 'Masukkan Nomor PAN',
    salaryStructure: 'Struktur Gaji',
    basicSalary: 'Gaji Pokok',
    enterBasicSalary: 'Masukkan Gaji Pokok',
    providentFund: 'Dana Pensiun',
    houseRentAllowance: 'Tunjangan Sewa Rumah',
    tds: 'TDS',
    medicalAllowance: 'Tunjangan Medis',
    professionalTax: 'Pajak Profesional',
    otherAllowance: 'Tunjangan Lainnya',
    esi: 'ESI',
    enteresi: 'Masukkan ESI',
    particulars: 'Detail',
    amount: 'Jumlah',
    grossEarnings: 'Penghasilan Kotor',
    totalDeductions: 'Total Potongan',
    netSalary: 'Gaji Bersih',
};
export default paymentSlip;
