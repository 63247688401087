const navbar = {
    calculator: 'ক্যালকুলেটর',
    resources: 'সম্পদ',
    integration: 'সংহতি',
    industries: 'শিল্প',
    pharmaceuticals: 'ফার্মাসিউটিক্যালস',
    fieldSales: 'ফিল্ড-সেলস',
    fmcg: 'এফএমসিজি',
    service: 'সেবা',
    fieldOperations: 'ফিল্ড অপারেশনস',
    logisticsAndTransportation: 'লজিস্টিক্স এবং পরিবহন',
    education: 'শিক্ষা',
    products: 'পণ্য',
    pricing: 'মূল্য নির্ধারণ',
    attendanceManagement: 'অ্যাটেনডেন্স ম্যানেজমেন্ট',
    fieldForceManagement: 'ফিল্ড ফোর্স ম্যানেজমেন্ট',
    company: 'কোম্পানি',
    ourStory: 'আমাদের কাহিনী',
    joinUs: 'আমাদের সাথে যোগদান করুন',
    contactUs: 'যোগাযোগ করুন',
    integration: 'ইন্টিগ্রেশন',
    becomeAPartner: 'পার্টনার হোন',
    blog: 'ব্লগ',
};
export default navbar;
