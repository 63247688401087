const navbar = {
    calculator: 'Calculadora',
    resources: 'Recursos',
    integration: 'Integração',
    industries: 'Indústrias',
    pharmaceuticals: 'Farmacêuticos',
    fieldSales: 'Vendas em Campo',
    fmcg: 'FMCG',
    service: 'Serviço',
    fieldOperations: 'Operações de Campo',
    logisticsAndTransportation: 'Logística e Transporte',
    education: 'Educação',
    products: 'Produtos',
    pricing: 'Preços',
    attendanceManagement: 'Gerenciamento de Presença',
    fieldForceManagement: 'Gerenciamento de Força de Campo',
    company: 'Empresa',
    ourStory: 'Nossa História',
    joinUs: 'Junte-se a Nós',
    contactUs: 'Contate-Nos',
    integration: 'Integração',
    becomeAPartner: 'Torne-se um Parceiro',
    blog: 'Blog',
};
export default navbar;
