const paymentSlip = {
    companyInformation: 'Company Information',
    companyAddress: 'Company Address',
    enterCompanyAddress: 'Enter Company Address',
    city: 'City',
    enterCity: 'Enter City',
    pincode: 'Pincode',
    enterPincode: 'Enter Pincode',
    country: 'Country',
    enterCountry: 'Enter Country',
    month: 'Month',
    year: 'Year',
    employeeDetails: 'Employee Details',
    employeeName: 'Employee Name',
    enterEmployeeName: 'Enter Employee Name',
    employeeId: 'Employee ID',
    enterEmployeeId: 'Enter Employee ID',
    dateOfJoining: 'Date of Joining',
    paidDays: 'Paid Days',
    enterPaidDays: 'Enter Paid Days',
    department: 'Department',
    enterDepartment: 'Enter Department',
    designation: 'Designation',
    enterDesignation: 'Enter Designation',
    bankName: 'Bank Name',
    enterBankName: 'Enter Bank Name',
    accountNumber: 'Account Number',
    enterAccountNumber: 'Enter Account Number',
    panNumber: 'PAN Number',
    enterPanNumber: 'Enter PAN Number',
    salaryStructure: 'Salary Structure',
    basicSalary: 'Basic Salary',
    enterBasicSalary: 'Enter Basic Salary',
    providentFund: 'Provident Fund',
    houseRentAllowance: 'House Rent Allowance',
    tds: 'TDS',
    medicalAllowance: 'Medical Allowance',
    professionalTax: 'Professional Tax',
    otherAllowance: 'Other Allowance',
    esi: 'ESI',
    enteresi: 'Enter ESI',
    particulars: 'Particulars',
    amount: 'Amount',
    grossEarnings: 'Gross Earnings',
    totalDeductions: 'Total Deductions',
    netSalary: 'Net Salary',
};
export default paymentSlip;
