const calculators = {
    calculators: 'कैलकुलेटर',
    heroSubTitle:
        'सैलरी कैलकुलेटर के माध्यम से अपनी CTC का विस्तृत विवरण जानें, जैसे कि सैलरी, ग्रेच्युटी राशि, और शहरवार न्यूनतम मजदूरी।',
    allCalculators: 'सभी कैलकुलेटर',
    salaryCalculator: {
        title: 'सैलरी कैलकुलेटर',
        subTitle: 'भारत में इन-हैंड सैलरी कैलकुलेटर',
        description: 'अपनी CTC का इन-हैंड सैलरी ब्रेकअप कैलकुलेट करें',
    },
    gratuityCalculator: {
        title: 'ग्रेच्युटी कैलकुलेटर',
        subTitle: 'भारत में ग्रेच्युटी कैलकुलेटर',
        description: 'ग्रेच्युटी कैलकुलेटर का उपयोग करें और कुछ आसान क्लिक में सटीक ग्रेच्युटी राशि जानें!',
    },
    fnfCalculator: {
        title: 'फुल एंड फाइनल कैलकुलेटर',
        subTitle: 'भारत में FnF सेटलमेंट कैलकुलेटर',
        description: 'FnF कैलकुलेटर का उपयोग करें और सेकंडों में सटीक सेटलमेंट राशि जानें।',
    },
    leaveEncashmentCalculator: {
        title: 'लीव एन्कैशमेंट कैलकुलेटर',
        subTitle: 'भारत में लीव एन्कैशमेंट कैलकुलेटर',
        description:
            'लीव एन्कैशमेंट कैलकुलेटर का उपयोग करें और जानें कि कर्मचारी को उसके नियोक्ता से लीव एन्कैशमेंट के रूप में कितनी राशि मिलेगी।',
    },
    ppfCalculator: {
        title: 'पब्लिक प्रोविडेंट फंड (PPF) कैलकुलेटर',
        subTitle: 'भारत में PPF कैलकुलेटर',
        description:
            'PPF कैलकुलेटर का उपयोग करें और PPF मैच्योरिटी राशि और उनके निवेश पर मिलने वाली ब्याज की गणना करें।',
    },
    salarySlipGenerator: {
        title: 'सैलरी स्लिप जनरेटर',
        subTitle: 'भारत में सैलरी स्लिप कैलकुलेटर',
        description:
            'हमारे फ्री ऑनलाइन सैलरी स्लिप जनरेटर का उपयोग करें और तुरंत अपनी सैलरी स्लिप जनरेट करें, साथ ही वित्तीय स्पष्टता प्राप्त करें।',
    },
};
export default calculators;
