const leaveEnhancementCalculator = {
    title: 'Calculadora de Mejora de Vacaciones',
    basicSalaryDA: 'Salario Base + DA',
    enterBasicSalary: 'Ingrese el Salario Base + DA',
    enternoOfEarnedLeaves: 'Ingrese el número de Días de Vacaciones Acumulados',
    noOfEarnedLeaves: 'Número de Días de Vacaciones Acumulados',
    particulars: 'Detalles',
    amount: 'Monto',
    lastDrawnBasicSalaryDA: 'Último Salario Base + DA Recibido',
    utilizedLeavesAfterretirement: 'Días de Vacaciones Utilizados Después de la Jubilación',
    leaveEnhancementReceived: 'Mejora de Vacaciones Recibida',
};
export default leaveEnhancementCalculator;
