const homePage = {
    heroHeader: 'સેલ્સ ઉત્તમતા અને ઓપરેશનલ કાર્યક્ષમતાને આગળ વધારવું',
    heroHeaderTypist: ['ઉપસ્થિતિ વ્યવસ્થાપન', 'ફીલ્ડ ટ્રેકિંગ મોનિટરિંગ', 'CRM'],
    heroHeaderSubText:
        'રિયલ-ટાઇમ લોકેશન આધારિત ઉપસ્થિતિ, લાઈવ ટ્રેકિંગ, કાર્ય અને ઓર્ડર મેનેજમેન્ટ, ડેટા અંદાજ અને API સેવાઓ',
    moreProductivityLessExpenses: 'ઝિયા ઉત્પાદનક્ષમતા, ઓછા ખર્ચ',
    keyFeatures: [
        {
            title: 'લેન્સ',
            description:
                'AI સહાય સાથે ટચલેસ ફેસિયલ ઉપસ્થિતિ ક્લોકિન અને ક્લોકઆઉટ. તમારું મોબાઇલ કેમેરા ઉપયોગ કરીને ઉપસ્થિતિ માર્ક કરો.',
        },
        {
            title: 'વેબ એપ',
            description:
                'વપરાશકર્તા મૈત્રીપૂર્ણ ડેશબોર્ડ, HR અને મેનેજર ટીમો સરળતાથી વિગતવાર અહેવાલ્સ માટે પ્રવેશ મેળવી શકે છે, કર્મચારી રેકોર્ડ મેનેજ કરી શકે છે, કાર્યક્ષમતા ટ્રેક કરી શકે છે અને પે રોલ તપાસી શકે છે.',
        },
        {
            title: 'હાયરાર્કી આધારિત નિયંત્રણ',
            description:
                'વિવિધ સંગઠન સ્તરોને સમાવી શકે તેવા હાયરાર્કી આધારિત નિયંત્રણ સિસ્ટમ, જેમાં એડમિનિસ્ટ્રેટિવ, HR મેનેજર્સ, સુપરવાઇઝર્સ અને કર્મચારીઓનો સમાવેશ થાય છે.',
        },
        {
            title: 'રાઉટ ઓપ્ટિમાઇઝેશન',
            description:
                'ડાયનેમિક રાઉટ હિસાબો, મલ્ટી-સ્ટોપ ઓપ્ટિમાઇઝેશન, ખર્ચ અંદાજ અને રિયલ-ટાઇમ અપડેટ્સ સાથે કાર્યક્ષમતા વધારવી અને ઓપરેશનલ ખર્ચ ઘટાડવો.',
        },
    ],
    features: 'ફીચર્સ',
    ourFeatures: 'અમારા ફીચર્સ સરળ, શક્તિશાળી અને કોઈપણ ઉદ્યોગ માટે સરળ ફિટ છે, જે સંસ્થાની ઉત્પાદનક્ષમતા વધારશે.',
    ourFeaturesObject: [
        {
            title: 'ઉપસ્થિતિ મોનિટરિંગ',
            description: 'લોકેશન સાથે ઉપસ્થિતિ માર્કિંગ & કાર્ય કલાકોને ટ્રેક કરો',
        },
        {
            title: 'ખર્ચ વ્યવસ્થાપન',
            description: 'ખર્ચોને ચોક્કસ રીતે ટ્રેક અને રેકોર્ડ કરવામાં આવે છે',
        },
        {
            title: 'કર્મચારી ટ્રેકિંગ',
            description: 'કર્મચારીની પ્રવૃત્તિઓ, કાર્યક્ષમતા મોનિટરિંગ અને રેકોર્ડિંગ',
        },
        {
            title: 'પે રોલ વ્યવસ્થાપન',
            description: 'પે રોલ પ્રક્રિયાને ઑપ્ટિમાઇઝ કરો અને ચોક્કસ પાયઉટ્સ',
        },
        {
            title: 'ઓર્ડર મેનેજમેન્ટ',
            description: 'બહુવિધ કસ્ટમાઇઝેબલ વ્યૂહરચનાઓ દ્વારા ઓર્ડર્સ/સંગ્રહોનું મેનેજમેન્ટ',
        },
        {
            title: 'રાઉટ ઓપ્ટિમાઇઝેશન',
            description: 'સમય, ઇંધણ, અને સ્ત્રોતોને બચાવવા માટે સૌથી અસરકારક માર્ગ શોધવો.',
        },
    ],
    whatWeDoQuestion: 'ટ્રેસફોર્સ શું કરે છે?',
    whatWeDoCards: [
        {
            title: 'કર્મચારી પ્રવૃત્તિઓને રિયલ-ટાઇમમાં વિશ્લેષણ કરો',
            description: 'દૈનિક અથવા કલાકવારની આધારે ટીમની પ્રવૃત્તિઓનું વિગતવાર વિશ્લેષણ મેળવો.',
        },
        {
            title: 'જીઓફેન્સ આધારિત ઉપસ્થિતિ અને ચેકિન અહેવાલ',
            description: 'સ્ટાન્ડર્ડ ઓપરેટિવ ઉપસ્થિતિ અને રજા મેનેજમેન્ટ સાથે ટીમની કાર્યક્ષમતા વધારવી.',
        },
        {
            title: 'સમર્થ ઓર્ડર/સંગ્રહ વ્યવસ્થાપન',
            description:
                'સ्मાર્ટ ઓર્ડર વ્યૂહરચનાઓ અને સમયસર સંગ્રહો સાથે ભૂલોને ઓછું કરો જ્યારે ઉત્પાદનક્ષમતા અને ગ્રાહક સંતોષ વધારવું.',
        },
        {
            title: 'એન્ડ ટુ એન્ડ કાર્ય વ્યવસ્થાપન',
            description:
                'પ્રોજેક્ટ યોજના સરળ બનાવવું, કામો વિલંબ વિના નિમણૂક કરો, અને તમારી ટીમ સભ્યો વચ્ચે રિયલ-ટાઇમ સહયોગ પ્રોત્સાહિત કરો.',
        },
        {
            title: 'ઑટોમેટેડ અહેવાલો પર આધારિત અંદાજો',
            description: 'કર્મચારી જોડાણને વિઝ્યુઅલાઇઝ કરો અને ખામીઓ વિના ટીમ વિશ્લેષણને પાર કરો.',
        },
        {
            title: 'અલાઉન્સ આધારિત ખર્ચો મેનેજ કરો',
            description: 'ઉપસ્થિતિ પત્રકોમાં રેકોર્ડના આધારે ઑનલાઇન payslips વહેંચવા માટે HR સિસ્ટમ્સ મેનેજ કરો.',
        },
    ],
    whatWeDoList: [
        'ઉપસ્થિતિ લોગ્સ',
        'જીઓ લોકેશન ટ્રેકર',
        'ક્લાઉડ સ્ટોરેજ',
        'એલર્ટ અને નોટિફિકેશન્સ',
        'પ્રોજેક્ટ મેનેજમેન્ટ',
        'સેલ્સ ફનલ મેનેજમેન્ટ',
    ],
    featureTitle: 'ફીચર્સ',
    featureSubTitle: 'અમારા ફીચર્સ સરળ, શક્તિશાળી અને કોઈપણ ઉદ્યોગ માટે સરળ ફિટ છે, જે સંસ્થાની ઉત્પાદનક્ષમતા વધારશે.',
    featureCards: [
        {
            title: 'ઉપસ્થિતિ મોનિટરિંગ',
            description: 'લોકેશન સાથે ઉપસ્થિતિ માર્કિંગ & કાર્ય કલાકોને ટ્રેક કરો',
        },
        {
            title: 'ખર્ચ વ્યવસ્થાપન',
            description: 'ખર્ચોને ચોક્કસ રીતે ટ્રેક અને રેકોર્ડ કરવામાં આવે છે',
        },
        {
            title: 'કર્મચારી ટ્રેકિંગ',
            description: 'કર્મચારીની પ્રવૃત્તિઓ, કાર્યક્ષમતા મોનિટરિંગ અને રેકોર્ડિંગ',
        },
        {
            title: 'ફીલ્ડ સેલ્સ એનાલિટિક્સ',
            description: 'સેલ્સ ડેટા મેળવો અને સેલ્સ કામગીરીને માપો',
        },
        {
            title: 'ઓર્ડર મેનેજમેન્ટ',
            description: 'બહુવિધ કસ્ટમાઇઝેબલ વ્યૂહરચનાઓ દ્વારા ઓર્ડર્સ/સંગ્રહોનું મેનેજમેન્ટ',
        },
        {
            title: 'લાઇવ રિપોર્ટિંગ',
            description: 'તમારા ફીલ્ડ કર્મચારીઓ ક્યાં છે અને કઈ કામગીરીમાં સંલગ્ન છે તે જાણો',
        },
    ],
    integrationTitle: 'ઉદ્યોગો',
    integrationSubTitle: 'દરેક ઉદ્યોગ માટે વર્કફોર્સ મેનેજમેન્ટ અને ઉત્પાદનક્ષમતા જોડવાનું',
    orderManagementSubtitle: 'આદેશ વ્યવસ્થાપન સિસ્ટમ કરતાં વધુ',
    orderManagementTitle: 'તમારી સેલ્સ ટીમ માટે ચોકસાઈથી ઓર્ડર અને સંગ્રહ વ્યવસ્થાપનને ઓટોમેટ, ટ્રેક અને ડિલિવર કરો.',
    orderManagementAccordian: [
        {
            title: 'ઇલેક્ટ્રોનિક ઓર્ડર એન્ટ્રી',
            points: [
                'ઓર્ડર સ્થિતિના રિયલ-ટાઇમ ટ્રેકિંગને સ્પષ્ટ કરવા માટે.',
                'ઓર્ડર્સ કૅપ્ચર કરીને પ્રક્રિયાને ડિજિટાઇઝ કરો અને મેન્યુઅલ ભૂલોને ઘટાડો.',
            ],
        },
        {
            title: 'ઑટોમેટેડ ઇન્વોઇસિંગ',
            points: [
                'ઓર્ડર વિગતોના આધારે ઝડપથી ઇન્વોઇસ બનાવો.',
                'ઝડપી પ્રક્રિયા અને ચુકવણી માટે ઇલેક્ટ્રોનિક રીતે ઇન્વોઇસ મોકલો.',
                'ઇન્વોઇસિંગ સામે ચુકવણીના OTP આધારિત ચકાસણી',
            ],
        },
        {
            title: 'સેલ્સ લક્ષ્ય',
            points: [
                'વિગતવાર અહેવાલો અને એનાલિટિક્સ સાથે લક્ષ્યો વિરુદ્ધ પ્રગતિ ટ્રેક કરો.',
                'ભૂમિકા અને જવાબદારીઓના આધારે ટીમ માટે સેલ્સ લક્ષ્યો સેટ અને મોનિટર કરો.',
            ],
        },
        {
            title: 'રિયલ ટાઇમ ઇન્વેન્ટરી ટ્રેકિંગ',
            points: [
                'પ્રતિ વાર સ્ટોક સ્તર માટે ચોક્કસ ગણતરી રાખો જ્યારે ઓર્ડર બનાવવામાં અને પૂર્ણ કરવામાં આવે છે.',
                'ઓછી સ્ટોકહોલ્ડ પર આપમેળે ખરીદીના ઓર્ડર જનરેટ કરવા માટે ફરીથી ઓર્ડર પોઇન્ટ સેટ કરો.',
            ],
        },
        {
            title: 'ઓર્ડર વિતરણ',
            points: [
                'વેરહાઉસથી ગ્રાહક સુધી માલની ખસેડણીની મોનિટરિંગ કરો, જવાબદારી અને પારદર્શકતા સુનિશ્ચિત કરો.',
                'સેલ્સ ટીમ વચ્ચે વિતરણ પ્રક્રિયાને પ્રાથમિકતા અને સ્થાન પર આધારિત ઓપ્ટિમાઇઝ કરો.',
                'ઝડપી ડિલિવરી અને ખર્ચ ઓપ્ટિમાઇઝેશન માટે AI આધારિત માર્ગ ઓપ્ટિમાઇઝેશન',
            ],
        },
    ],
    whyChooseTraceforce: 'ટ્રેસફોર્સને શા માટે પસંદ કરવો',
    whyChooseTraceforceSubtitle:
        'એડમિનિસ્ટ્રેટિવ કાર્યને ઓટોમેટ કરીને, પ્રક્રિયાઓને સરળ બનાવવું, અને કર્મચારી જોડાણને વધારવું સાથે માનવ સંસાધન વ્યવસ્થાપનનો ક્રાંતિ.',
    whyChooseTraceForceList: [
        'ઉત્પાદનક્ષમતા સુધારણ',
        'સમય કાર્યક્ષમતા',
        'ચૂકીઓ સાથે ચોકસાઈ',
        'રિયલ-ટાઇમ મોનિટરિંગ',
        'પૂરી રીતે પ્રતિસાદાત્મક',
        'AI આધારિત ઑપ્ટિમાઇઝેશન',
        'માપદંડ આધારિત કસ્ટમાઇઝેશન',
        'બહુવિધ શાખા સપોર્ટ',
        '24x7 સપોર્ટ સેવા',
    ],
    calculatorTitle: 'કૅલ્ક્યુલેટર',
    calculatorSubtitle:
        'અમારા ફીચર્સ સરળ, શક્તિશાળી, અને કોઈપણ ઉદ્યોગ માટે સરળ ફિટ છે, જે સંસ્થાની ઉત્પાદનક્ષમતા વધારશે.',
    workingHours: 'કામકાજના કલાક',
    uncountedHours: 'ગણવામાં ન આવેલા કલાકો',
    dailySavings: 'દૈનિક બચત',
    monthlySavings: 'માસિક બચત',
    productivityGrowth: 'ઉત્પાદનક્ષમતા વૃદ્ધિ',
    numberOfEmployees: 'કર્મચારીઓની સંખ્યા',
    faqs: 'આંતર ક્વેસ્ટન',
    frequentlyAskedQuestions: 'વારંવાર પૂછાતા પ્રશ્નો',
    faqLine: 'અમારા ગ્રાહકો માટે કેટલાક મૂળભૂત પ્રકારના પ્રશ્નો અહીં છે',
    faqAccordian: [
        {
            question: 'મારી કંપનીને કેવી રીતે સાઇન અપ કરી શકું?',
            ans: 'તમે અમારા મોબાઇલ એપ અથવા વેબ એપ પર 3 સરળ પગલાંમાં TraceForce પર સાઇન અપ કરી શકો છો. ફક્ત તમારું નામ, ઇમેલ અને GST નંબર ઉમેરો અને તમે તૈયાર છો.',
        },
        {
            question: 'હું મારા લોગિનને કેવી રીતે પ્રમાણિત કરી શકું?',
            ans: 'TraceForce તમને વિવિધ ક્લોક ઇન વિકલ્પો પ્રદાન કરે છે. ઉદાહરણ તરીકે, અમારા પાસે ડિફૉલ્ટ મોબાઇલ લોગિન વિકલ્પ, સેલ્ફી આધારિત વિકલ્પ અને છેલ્લે પરંતુ સૌથી સુરક્ષિત AI આધારિત ફેસ સત્તાપન છે.',
        },
        {
            question: 'ઉપસ્થિતિ કેવી રીતે માર્ક કરવી?',
            ans: 'યુઝર માત્ર ત્યારે જ ઉપસ્થિતિ માર્ક કરી શકે છે જ્યારે વ્યક્તિ નિર્ધારિત જિઓફેન્સમાં હોય. પ્રોક્સીને સુરક્ષિત કરવા માટે, અમને વિવિધ પ્રમાણન પદ્ધતિઓ અમલમાં રાખી છે.',
        },
        {
            question: 'શું અમે કાર્ય પૂર્ણ કરવા માટે સ્થાન નકલી કરી શકીએ છીએ અથવા સમય બદલી શકીએ છીએ?',
            ans: 'TraceForce કોઈ પણ સ્પામ અથવા અનવિશ્વસનીય પ્રવૃત્તિઓને શોધે છે અને એપ્લિકેશનને નિશ્ચિત કરશે અને વપરાશકર્તાને સૂચના આપશે.',
        },
        {
            question: 'ટ્રેસફોર્સ રીમોટ કર્મચારીઓને સપોર્ટ કરે છે?',
            ans: 'TraceForce Hourly આધારિત કરાર કર્મચારીઓને સપોર્ટ કરે છે અને તેનાથી ફિક્સ્ડ હોર અથવા લવચીક હોર શિફ્ટના આધાર પર પે રોલ ગણવે છે.',
        },
        {
            question: 'શું હું મારા વર્કફોર્સને મોબાઇલ દ્વારા ટ્રેક કરી શકું?',
            ans: 'TraceForce તમને મોબાઇલ અને વેબ બંને દ્વારા તમારા વર્કફોર્સને ટ્રેક અને મેનેજ કરવામાં મદદ કરે છે, જેના કારણે તમને દિવસે એક વિહંગાવલોકન મળે છે.',
        },
        {
            question: 'HRMS સિવાય ટ્રેસફોર્સને અન્ય શું સેવા આપે છે?',
            ans: 'TraceForce વ્યવસ્થાપક અથવા વ્યાપાર માલિકોના કાર્યને સરળ બનાવવા માટે વિશાળ સેવા યાદી આપે છે અને તેમને ફક્ત જરૂરી સૂચનાઓની સાથે મદદ કરે છે.',
        },
    ],

    referAndGet: 'ભલામણ કરો અને મેળવો',
    referAndGetH2: 'TraceForce વાર્ષિક પેકેજ પર 8% છૂટ મેળવો!',
    referAndGetp:
        'મિત્ર અથવા ગ્રાહકને TraceForce પર ભલામણ કરો અને તેઓ અમારો ભાગ બન્યા પછી તમારું વાર્ષિક પેકેજ પર 8% છૂટ મેળવો! ફાયદા વહેંચો અને સાથે બચત કરો!',
};
export default homePage;
