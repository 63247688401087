const fnfCalculator = {
    title: 'آلة حاسبة للتسوية النهائية',
    basicSalary: 'الراتب الأساسي (مطلوب)',
    earnedLeaves: 'الإجازات المستحقة (مطلوب)',
    paydaysInMonth: 'أيام الدفع في الشهر (مطلوب)',
    noticePeriodSalary: 'راتب فترة الإشعار (اختياري)',
    gratuityPay: 'مكافأة نهاية الخدمة (اختياري)',
    bonus: 'المكافأة (اختياري)',
    deductions: 'الخصومات (EPF، ESI، الضرائب) (اختياري)',
    totalFinalSettlementAmount: 'إجمالي مبلغ التسوية النهائية',
    enterBasicSalary: 'أدخل الراتب الأساسي',
    enterEarnedLeaves: 'أدخل الإجازات المستحقة',
    enterNumberOfPaydaysInMonth: 'أدخل عدد أيام الدفع في الشهر',
    enterNoticePeriodSalary: 'أدخل راتب فترة الإشعار',
    enterGratuityPay: 'أدخل مكافأة نهاية الخدمة',
    enterBonusAmount: 'أدخل مبلغ المكافأة',
    enterTotalDeductions: 'أدخل إجمالي الخصومات',
};
export default fnfCalculator;
