const calculators = {
    calculators: 'Calculadoras',
    heroSubTitle:
        'Conozca el desglose detallado de su CTC, como salario, cantidad de gratificación y salarios mínimos por ciudad con calculadoras salariales.',
    allCalculators: 'Todas las calculadoras',
    salaryCalculator: {
        title: 'Calculadora de Salarios',
        subTitle: 'Calculadora de Salario Neto en India',
        description: 'Calcule el desglose de su salario neto basado en su CTC.',
    },
    gratuityCalculator: {
        title: 'Calculadora de Gratificación',
        subTitle: 'Calculadora de Gratificación en India',
        description:
            '¡Utilice la calculadora de gratificación para conocer la cantidad exacta de gratificación en unos pocos clics!',
    },
    fnfCalculator: {
        title: 'Calculadora de Liquidación Completa y Final',
        subTitle: 'Calculadora de Liquidación FnF en India',
        description: 'Utilice la calculadora FnF para conocer la cantidad exacta de liquidación en segundos.',
    },
    leaveEncashmentCalculator: {
        title: 'Calculadora de Liquidación de Días de Vacaciones',
        subTitle: 'Calculadora de Liquidación de Días de Vacaciones en India',
        description:
            'Utilice la calculadora de liquidación de vacaciones para calcular la cantidad que un empleado debe recibir de su empleador.',
    },
    ppfCalculator: {
        title: 'Calculadora de Fondo de Previsión Pública (PPF)',
        subTitle: 'Calculadora de PPF en India',
        description:
            'Utilice la calculadora PPF para calcular la cantidad de madurez y los intereses que recibirá en su inversión.',
    },
    salarySlipGenerator: {
        title: 'Generador de Recibos de Salario',
        subTitle: 'Calculadora de Recibos de Salario en India',
        description:
            'Genere sus recibos de salario al instante con nuestro generador gratuito en línea y obtenga claridad financiera al instante.',
    },
};
export default calculators;
