const paymentSlip = {
    companyInformation: 'Информация о компании',
    companyAddress: 'Адрес компании',
    enterCompanyAddress: 'Введите адрес компании',
    city: 'Город',
    enterCity: 'Введите город',
    pincode: 'Почтовый индекс',
    enterPincode: 'Введите почтовый индекс',
    country: 'Страна',
    enterCountry: 'Введите страну',
    month: 'Месяц',
    year: 'Год',
    employeeDetails: 'Данные сотрудника',
    employeeName: 'Имя сотрудника',
    enterEmployeeName: 'Введите имя сотрудника',
    employeeId: 'Идентификатор сотрудника',
    enterEmployeeId: 'Введите идентификатор сотрудника',
    dateOfJoining: 'Дата приема на работу',
    paidDays: 'Оплаченные дни',
    enterPaidDays: 'Введите количество оплаченных дней',
    department: 'Отдел',
    enterDepartment: 'Введите отдел',
    designation: 'Должность',
    enterDesignation: 'Введите должность',
    bankName: 'Название банка',
    enterBankName: 'Введите название банка',
    accountNumber: 'Номер счета',
    enterAccountNumber: 'Введите номер счета',
    panNumber: 'PAN-номер',
    enterPanNumber: 'Введите PAN-номер',
    salaryStructure: 'Структура заработной платы',
    basicSalary: 'Базовая зарплата',
    enterBasicSalary: 'Введите базовую зарплату',
    providentFund: 'Пенсионный фонд',
    houseRentAllowance: 'Надбавка на аренду жилья',
    tds: 'TDS',
    medicalAllowance: 'Медицинская надбавка',
    professionalTax: 'Профессиональный налог',
    otherAllowance: 'Прочие надбавки',
    esi: 'ESI',
    enteresi: 'Введите ESI',
    particulars: 'Детали',
    amount: 'Сумма',
    grossEarnings: 'Валовой доход',
    totalDeductions: 'Общие вычеты',
    netSalary: 'Чистая зарплата',
};
export default paymentSlip;
