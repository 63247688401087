import fnfCalculator from './calculators/fnfCalculator';
import gratuityCalculator from './calculators/gratuityCalculator';
import leaveEnhancementCalculator from './calculators/leaveEnhancementCalculator';
import paymentSlip from './calculators/paymentSlip';
import ppfCalculator from './calculators/ppfCalculator';
import salaryCalculator from './calculators/salaryCalculator';

const indivisualCalculators = {
    salaryCalculator,
    gratuityCalculator,
    fnfCalculator,
    leaveEnhancementCalculator,
    ppfCalculator,
    paymentSlip,
};
export default indivisualCalculators;
