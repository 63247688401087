const salaryCalculator = {
    enterAnnualCTC: 'أدخل الراتب السنوي (CTC)',
    enterAnnualCTCPlaceholder: 'أدخل راتبك السنوي واضغط على إدخال',
    component: 'المكون',
    monthly: 'شهري',
    annual: 'سنوي',
    grossSalary: 'الراتب الإجمالي',
    basicSalary: 'الراتب الأساسي',
    hra: 'بدل الإيجار (HRA)',
    specialAllowance: 'بدل خاص',
    employeeContribution: 'مساهمة الموظف',
    epf: 'صندوق ادخار الموظفين (EPF)',
    labourWelfareFund: 'صندوق رفاه العمال (LWF)',
    esic: 'مؤسسة التأمينات الاجتماعية للموظفين (ESIC)',
    employerContribution: 'مساهمة صاحب العمل',
    takeHomeSalary: 'الراتب الصافي',
};
export default salaryCalculator;
