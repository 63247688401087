const paymentSlip = {
    companyInformation: "Informations sur l'entreprise",
    companyAddress: "Adresse de l'entreprise",
    enterCompanyAddress: "Entrez l'adresse de l'entreprise",
    city: 'Ville',
    enterCity: 'Entrez la ville',
    pincode: 'Code postal',
    enterPincode: 'Entrez le code postal',
    country: 'Pays',
    enterCountry: 'Entrez le pays',
    month: 'Mois',
    year: 'Année',
    employeeDetails: "Détails de l'employé",
    employeeName: "Nom de l'employé",
    enterEmployeeName: "Entrez le nom de l'employé",
    employeeId: "ID de l'employé",
    enterEmployeeId: "Entrez l'ID de l'employé",
    dateOfJoining: "Date d'embauche",
    paidDays: 'Jours payés',
    enterPaidDays: 'Entrez les jours payés',
    department: 'Département',
    enterDepartment: 'Entrez le département',
    designation: 'Désignation',
    enterDesignation: 'Entrez la désignation',
    bankName: 'Nom de la banque',
    enterBankName: 'Entrez le nom de la banque',
    accountNumber: 'Numéro de compte',
    enterAccountNumber: 'Entrez le numéro de compte',
    panNumber: 'Numéro PAN',
    enterPanNumber: 'Entrez le numéro PAN',
    salaryStructure: 'Structure salariale',
    basicSalary: 'Salaire de base',
    enterBasicSalary: 'Entrez le salaire de base',
    providentFund: 'Fonds de prévoyance',
    houseRentAllowance: 'Allocation de logement',
    tds: 'TDS',
    medicalAllowance: 'Allocation médicale',
    professionalTax: 'Taxe professionnelle',
    otherAllowance: 'Autres allocations',
    esi: 'ESI',
    enteresi: 'Entrez ESI',
    particulars: 'Particularités',
    amount: 'Montant',
    grossEarnings: 'Revenus bruts',
    totalDeductions: 'Déductions totales',
    netSalary: 'Salaire net',
};
export default paymentSlip;
