const paymentSlip = {
    companyInformation: 'कंपनी की जानकारी',
    companyAddress: 'कंपनी का पता',
    enterCompanyAddress: 'कंपनी का पता दर्ज करें',
    city: 'शहर',
    enterCity: 'शहर दर्ज करें',
    pincode: 'पिनकोड',
    enterPincode: 'पिनकोड दर्ज करें',
    country: 'देश',
    enterCountry: 'देश दर्ज करें',
    month: 'महीना',
    year: 'वर्ष',
    employeeDetails: 'कर्मचारी विवरण',
    employeeName: 'कर्मचारी का नाम',
    enterEmployeeName: 'कर्मचारी का नाम दर्ज करें',
    employeeId: 'कर्मचारी आईडी',
    enterEmployeeId: 'कर्मचारी आईडी दर्ज करें',
    dateOfJoining: 'जॉइनिंग की तारीख',
    paidDays: 'भुगतान किए गए दिन',
    enterPaidDays: 'भुगतान किए गए दिन दर्ज करें',
    department: 'विभाग',
    enterDepartment: 'विभाग दर्ज करें',
    designation: 'पदनाम',
    enterDesignation: 'पदनाम दर्ज करें',
    bankName: 'बैंक का नाम',
    enterBankName: 'बैंक का नाम दर्ज करें',
    accountNumber: 'खाता नंबर',
    enterAccountNumber: 'खाता नंबर दर्ज करें',
    panNumber: 'पैन नंबर',
    enterPanNumber: 'पैन नंबर दर्ज करें',
    salaryStructure: 'वेतन संरचना',
    basicSalary: 'मूल वेतन',
    enterBasicSalary: 'मूल वेतन दर्ज करें',
    providentFund: 'भविष्य निधि',
    houseRentAllowance: 'मकान किराया भत्ता',
    tds: 'टीडीएस',
    medicalAllowance: 'चिकित्सा भत्ता',
    professionalTax: 'पेशेवर कर',
    otherAllowance: 'अन्य भत्ते',
    esi: 'ईएसआई',
    enteresi: 'ईएसआई दर्ज करें',
    particulars: 'विशेषताएं',
    amount: 'राशि',
    grossEarnings: 'कुल आय',
    totalDeductions: 'कुल कटौती',
    netSalary: 'शुद्ध वेतन',
};
export default paymentSlip;
