const calculators = {
    calculators: 'કૅલ્ક્યુલેટર',
    heroSubTitle:
        'સેલરી કૅલ્ક્યુલેટર દ્વારા તમારી CTC નું વિગતવાર વિભાજન જાણો, જેમ કે સેલરી, ગ્રેચ્યુટી રકમ, અને શહેરવાર ન્યૂનતમ મજૂરી.',
    allCalculators: 'બધા કૅલ્ક્યુલેટર',
    salaryCalculator: {
        title: 'સેલરી કૅલ્ક્યુલેટર',
        subTitle: 'ભારતમાં હસ્તગત સેલરી કૅલ્ક્યુલેટર',
        description: 'તમારા CTC ની હસ્તગત સેલરી બ્રેકઅપની ગણતરી કરો',
    },
    gratuityCalculator: {
        title: 'ગ્રેચ્યુટી કૅલ્ક્યુલેટર',
        subTitle: 'ભારતમાં ગ્રેચ્યુટી કૅલ્ક્યુલેટર',
        description: 'ગ્રેચ્યુટી કૅલ્ક્યુલેટરનો ઉપયોગ કરીને થોડા સરળ ક્લિકમાં સચોટ ગ્રેચ્યુટી રકમ જાણો!',
    },
    fnfCalculator: {
        title: 'ફુલ એન્ડ ફાઇનલ કૅલ્ક્યુલેટર',
        subTitle: 'ભારતમાં FnF સેટલમેન્ટ કૅલ્ક્યુલેટર',
        description: 'FnF કૅલ્ક્યુલેટરનો ઉપયોગ કરીને થોડા સેકંડમાં સચોટ સેટલમેન્ટ રકમ જાણો.',
    },
    leaveEncashmentCalculator: {
        title: 'છુટ્ટી રોકાણ કૅલ્ક્યુલેટર',
        subTitle: 'ભારતમાં છુટ્ટી રોકાણ કૅલ્ક્યુલેટર',
        description:
            'છુટ્ટી રોકાણ કૅલ્ક્યુલેટરનો ઉપયોગ કરો અને જાણી લો કે એક કર્મચારી તેની નોકરીદાતાથી કેટલી રકમ છુટ્ટી રોકાણ રૂપે પ્રાપ્ત કરે છે.',
    },
    ppfCalculator: {
        title: 'પબ્લિક પ્રોવિડન્ટ ફંડ (PPF) કૅલ્ક્યુલેટર',
        subTitle: 'ભારતમાં PPF કૅલ્ક્યુલેટર',
        description: 'PPF કૅલ્ક્યુલેટરનો ઉપયોગ કરીને PPF મચ્યોરિટી રકમ અને તેમના રોકાણ પર મળનારી વ્યાજની ગણતરી કરો.',
    },
    salarySlipGenerator: {
        title: 'સેલરી સ્લિપ જનરેટર',
        subTitle: 'ભારતમાં સેલરી સ્લિપ કૅલ્ક્યુલેટર',
        description:
            'અમારા ફ્રી ઓનલાઈન સેલરી સ્લિપ જનરેટરનો ઉપયોગ કરીને તરત જ તમારી સેલરી સ્લિપ જનરેટ કરો અને મૂડી વ્યવહાર માટે સ્પષ્ટતા મેળવો.',
    },
};
export default calculators;
