const calculators = {
    calculators: 'Kalkulator',
    heroSubTitle:
        'Ketahui rincian CTC Anda seperti gaji, jumlah tunjangan, dan upah minimum berdasarkan kota dengan kalkulator gaji.',
    allCalculators: 'Semua Kalkulator',
    salaryCalculator: {
        title: 'Kalkulator Gaji',
        subTitle: 'Kalkulator Gaji di Tangan di India',
        description: 'Hitung rincian gaji di tangan dari CTC Anda',
    },
    gratuityCalculator: {
        title: 'Kalkulator Tunjangan',
        subTitle: 'Kalkulator Tunjangan di India',
        description:
            'Gunakan kalkulator tunjangan untuk mengetahui jumlah tunjangan yang tepat dengan beberapa klik sederhana!',
    },
    fnfCalculator: {
        title: 'Kalkulator Full & Final',
        subTitle: 'Kalkulator Penyelesaian FnF di India',
        description: 'Gunakan kalkulator FnF untuk mengetahui jumlah penyelesaian yang tepat dalam beberapa detik.',
    },
    leaveEncashmentCalculator: {
        title: 'Kalkulator Pembayaran Cuti',
        subTitle: 'Kalkulator Pembayaran Cuti di India',
        description:
            'Gunakan kalkulator pembayaran cuti untuk menghitung jumlah yang harus diterima seorang karyawan dari majikannya.',
    },
    ppfCalculator: {
        title: 'Kalkulator Dana Pensiun Publik (PPF)',
        subTitle: 'Kalkulator PPF di India',
        description:
            'Gunakan kalkulator PPF untuk menghitung jumlah jatuh tempo PPF dan bunga yang akan diterima dari investasi mereka.',
    },
    salarySlipGenerator: {
        title: 'Generator Slip Gaji',
        subTitle: 'Kalkulator Slip Gaji di India',
        description:
            'Hasilkan slip gaji Anda secara instan dengan generator slip gaji online gratis kami dan dapatkan kejelasan finansial saat bepergian.',
    },
};
export default calculators;
