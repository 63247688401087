const fnfCalculator = {
    title: 'Calculateur de règlement final et complet',
    basicSalary: 'Salaire de base (Requis)',
    earnedLeaves: 'Congés accumulés (Requis)',
    paydaysInMonth: 'Jours de paie dans un mois (Requis)',
    noticePeriodSalary: 'Salaire de la période de préavis (Facultatif)',
    gratuityPay: 'Indemnité de départ (Facultatif)',
    bonus: 'Prime (Facultatif)',
    deductions: 'Déductions (EPF, ESI, Impôts) (Facultatif)',
    totalFinalSettlementAmount: 'Montant total du règlement final',
    enterBasicSalary: 'Entrez le salaire de base',
    enterEarnedLeaves: 'Entrez les congés accumulés',
    enterNumberOfPaydaysInMonth: 'Entrez le nombre de jours de paie dans un mois',
    enterNoticePeriodSalary: 'Entrez le salaire de la période de préavis',
    enterGratuityPay: "Entrez l'indemnité de départ",
    enterBonusAmount: 'Entrez le montant de la prime',
    enterTotalDeductions: 'Entrez le montant total des déductions',
};
export default fnfCalculator;
