const ppfCalculator = {
    title: 'PPF கணக்கீட்டாளர்',
    yearlyInvestment: 'ஆண்டு முதலீடு:',
    enterYearlyInvestment: 'ஆண்டு முதலீட்டை உள்ளிடவும்',
    currentPPFInterestRate: 'தற்போதைய PPF வட்டி விகிதம் (%):',
    enterPPFInterestRate: 'PPF வட்டி விகிதத்தை உள்ளிடவும்',
    duration: 'கால அளவு (ஆண்டுகளில்):',
    enterDuration: 'முதலீட்டின் கால அளவை உள்ளிடவும்',
    particulars: 'விவரங்கள்',
    amount: 'தொகை',
    investedAmount: 'முதலீட்டுத்தொகை',
    totalInterest: 'மொத்த வட்டி',
    ppfMaturity: 'PPF மதிப்பு',
};
export default ppfCalculator;
