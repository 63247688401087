const gratuityCalculator = {
    enterTheRequiredDetailsToCalculateYourGratuity:
        'আপনার গ্রাচুইটি হিসাব করার জন্য প্রয়োজনীয় বিস্তারিত তথ্য প্রবেশ করুন',
    lastDrawnSalary: 'শেষ প্রাপ্ত বেতন (মূল বেতন + DA)',
    enterLastDrawnSalary: 'শেষ প্রাপ্ত বেতন প্রবেশ করুন',
    numberOfYearsWorked: 'কর্মরত বছর সংখ্যা (ন্যূনতম ৫ বছর)',
    enterYearsOfService: 'সেবার বছরগুলি প্রবেশ করুন',
    totalGratuityPayable: 'মোট গ্রাচুইটি প্রদেয়',
};
export default gratuityCalculator;
