const fnfCalculator = {
    title: 'फुल एंड फाइनल सेटलमेंट कैलक्युलेटर',
    basicSalary: 'बेसिक सैलरी (आवश्यक)',
    earnedLeaves: 'अर्जित छुट्टियाँ (आवश्यक)',
    paydaysInMonth: 'महीने में पेडे डेज़ (आवश्यक)',
    noticePeriodSalary: 'नोटिस पीरियड सैलरी (वैकल्पिक)',
    gratuityPay: 'ग्रेच्युटी पे (वैकल्पिक)',
    bonus: 'बोनस (वैकल्पिक)',
    deductions: 'काटौतियां (EPF, ESI, TAX) (वैकल्पिक)',
    totalFinalSettlementAmount: 'कुल अंतिम सेटलमेंट राशि',
    enterBasicSalary: 'बेसिक सैलरी दर्ज करें',
    enterEarnedLeaves: 'अर्जित छुट्टियाँ दर्ज करें',
    enterNumberOfPaydaysInMonth: 'महीने में पेडे डेज़ की संख्या दर्ज करें',
    enterNoticePeriodSalary: 'नोटिस पीरियड सैलरी दर्ज करें',
    enterGratuityPay: 'ग्रेच्युटी पे दर्ज करें',
    enterBonusAmount: 'बोनस राशि दर्ज करें',
    enterTotalDeductions: 'कुल कटौतियां दर्ज करें',
};
export default fnfCalculator;
