const gratuityCalculator = {
    enterTheRequiredDetailsToCalculateYourGratuity:
        'Masukkan detail yang diperlukan untuk menghitung tunjangan hari tua Anda',
    lastDrawnSalary: 'Gaji Terakhir yang Ditarik (Gaji Pokok + DA)',
    enterLastDrawnSalary: 'Masukkan Gaji Terakhir yang Ditarik',
    numberOfYearsWorked: 'Jumlah Tahun Bekerja (minimal 5 tahun)',
    enterYearsOfService: 'Masukkan Jumlah Tahun Layanan',
    totalGratuityPayable: 'Total Tunjangan Hari Tua yang Harus Dibayar',
};
export default gratuityCalculator;
