const gratuityCalculator = {
    enterTheRequiredDetailsToCalculateYourGratuity:
        'Entrez les détails nécessaires pour calculer votre prime de fin de service',
    lastDrawnSalary: 'Dernier salaire perçu (Salaire de base + DA)',
    enterLastDrawnSalary: 'Entrez le dernier salaire perçu',
    numberOfYearsWorked: "Nombre d'années travaillées (minimum 5 ans)",
    enterYearsOfService: "Entrez le nombre d'années de service",
    totalGratuityPayable: 'Total de la prime de fin de service à verser',
};
export default gratuityCalculator;
