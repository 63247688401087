const calculators = {
    calculators: 'الآلات الحاسبة',
    heroSubTitle:
        'اعرف التفاصيل الكاملة لراتبك مثل الراتب، مبلغ مكافأة نهاية الخدمة، وأدنى الأجور حسب المدينة باستخدام الآلات الحاسبة للرواتب.',
    allCalculators: 'جميع الآلات الحاسبة',
    salaryCalculator: {
        title: 'آلة حاسبة للراتب',
        subTitle: 'حاسبة الراتب في الهند',
        description: 'احسب تفصيل الراتب اليدوي لراتبك الإجمالي',
    },
    gratuityCalculator: {
        title: 'حاسبة المكافأة',
        subTitle: 'حاسبة المكافأة في الهند',
        description: 'استخدم حاسبة المكافأة لمعرفة المبلغ الدقيق للمكافأة في بضع نقرات بسيطة!',
    },
    fnfCalculator: {
        title: 'حاسبة التسوية النهائية',
        subTitle: 'حاسبة التسوية النهائية في الهند',
        description: 'استخدم حاسبة التسوية النهائية لمعرفة المبلغ الدقيق للتسوية في ثوانٍ.',
    },
    leaveEncashmentCalculator: {
        title: 'حاسبة تعويض الإجازات',
        subTitle: 'حاسبة تعويض الإجازات في الهند',
        description: 'استخدم حاسبة تعويض الإجازات لحساب المبلغ الذي يجب أن يتلقاه الموظف من صاحب العمل عن إجازاته.',
    },
    ppfCalculator: {
        title: 'حاسبة صندوق الادخار العام (PPF)',
        subTitle: 'حاسبة صندوق الادخار العام في الهند',
        description: 'استخدم حاسبة صندوق الادخار العام لحساب مبلغ استحقاق PPF والفائدة التي سيتلقاها من استثماره.',
    },
    salarySlipGenerator: {
        title: 'مولد كشف الراتب',
        subTitle: 'حاسبة كشف الراتب في الهند',
        description:
            'أنشئ كشف الراتب الخاص بك على الفور باستخدام مولد كشف الراتب المجاني عبر الإنترنت واحصل على وضوح مالي أثناء التنقل.',
    },
};
export default calculators;
