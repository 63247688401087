const calculators = {
    calculators: 'Calculadoras',
    heroSubTitle:
        'Saiba o detalhamento do seu CTC, como salário, valor de gratificação e salários mínimos por cidade com as calculadoras de salário.',
    allCalculators: 'Todas as Calculadoras',
    salaryCalculator: {
        title: 'Calculadora de Salário',
        subTitle: 'Calculadora de Salário em Mãos na Índia',
        description: 'Calcule o detalhamento do seu salário em mãos a partir do seu CTC',
    },
    gratuityCalculator: {
        title: 'Calculadora de Gratificação',
        subTitle: 'Calculadora de Gratificação na Índia',
        description:
            'Use a calculadora de gratificação para saber o valor exato da gratificação com alguns cliques simples!',
    },
    fnfCalculator: {
        title: 'Calculadora de Rescisão Total',
        subTitle: 'Calculadora de Rescisão FnF na Índia',
        description: 'Use a calculadora FnF para saber o valor exato da rescisão em segundos.',
    },
    leaveEncashmentCalculator: {
        title: 'Calculadora de Resgate de Férias',
        subTitle: 'Calculadora de Resgate de Férias na Índia',
        description:
            'Use a Calculadora de Resgate de Férias para calcular o valor que um funcionário deve receber do empregador.',
    },
    ppfCalculator: {
        title: 'Calculadora de Fundo de Pensão Público (PPF)',
        subTitle: 'Calculadora PPF na Índia',
        description:
            'Use a Calculadora PPF para calcular o valor de vencimento do PPF e os juros que serão recebidos sobre o investimento.',
    },
    salarySlipGenerator: {
        title: 'Gerador de Contracheque',
        subTitle: 'Calculadora de Contracheque na Índia',
        description:
            'Gere seu contracheque instantaneamente com nosso gerador de contracheque online gratuito e tenha clareza financeira em movimento.',
    },
};
export default calculators;
