const leaveEnhancementCalculator = {
    title: 'Leave Enhancement Calculator',
    basicSalaryDA: 'Basic Salary + DA',
    enterBasicSalary: 'Enter Basic Salary + DA',
    enternoOfEarnedLeaves: 'Enter No. of Earned Leaves',
    noOfEarnedLeaves: 'No. of Earned Leaves',
    particulars: 'Particulars',
    amount: 'Amount',
    lastDrawnBasicSalaryDA: 'Last Drawn Basic Salary + DA',
    utilizedLeavesAfterretirement: 'Utilized Leaves after retirement',
    leaveEnhancementReceived: 'Leave Enhancement Received',
};
export default leaveEnhancementCalculator;
