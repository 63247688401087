const ppfCalculator = {
    title: 'PPF ক্যালকুলেটর',
    yearlyInvestment: 'বার্ষিক বিনিয়োগ:',
    enterYearlyInvestment: 'বার্ষিক বিনিয়োগ লিখুন',
    currentPPFInterestRate: 'বর্তমান PPF সুদের হার (%):',
    enterPPFInterestRate: 'PPF সুদের হার লিখুন',
    duration: 'সময়কাল (বছরে):',
    enterDuration: 'বিনিয়োগের সময়কাল লিখুন',
    particulars: 'বিশেষ তথ্য',
    amount: 'পরিমাণ',
    investedAmount: 'বিনিয়োগের পরিমাণ',
    totalInterest: 'মোট সুদ',
    ppfMaturity: 'PPF মেয়াদপূর্তি',
};
export default ppfCalculator;
// Compare this snippet from src/pages/landings/resources/calculator/GratuityCalculator.jsx:
