const ppfCalculator = {
    title: 'Калькулятор PPF',
    yearlyInvestment: 'Ежегодные инвестиции:',
    enterYearlyInvestment: 'Введите ежегодные инвестиции',
    currentPPFInterestRate: 'Текущая ставка процента PPF (%):',
    enterPPFInterestRate: 'Введите процентную ставку PPF',
    duration: 'Срок (в годах):',
    enterDuration: 'Введите срок инвестиций',
    particulars: 'Детали',
    amount: 'Сумма',
    investedAmount: 'Инвестированная сумма',
    totalInterest: 'Общий процент',
    ppfMaturity: 'Срок погашения PPF',
};
export default ppfCalculator;
// Compare this snippet from src/pages/landings/resources/calculator/GratuityCalculator.jsx:
