const leaveEnhancementCalculator = {
    title: 'ছুটি বৃদ্ধি ক্যালকুলেটর',
    basicSalaryDA: 'মূল বেতন + DA',
    enterBasicSalary: 'মূল বেতন + DA প্রবেশ করুন',
    enternoOfEarnedLeaves: 'অর্জিত ছুটির সংখ্যা প্রবেশ করুন',
    noOfEarnedLeaves: 'অর্জিত ছুটির সংখ্যা',
    particulars: 'বিশদ',
    amount: 'পরিমাণ',
    lastDrawnBasicSalaryDA: 'শেষ প্রাপ্ত মূল বেতন + DA',
    utilizedLeavesAfterretirement: 'অবসর পরবর্তী ব্যবহৃত ছুটির সংখ্যা',
    leaveEnhancementReceived: 'প্রাপ্ত ছুটি বৃদ্ধির পরিমাণ',
};
export default leaveEnhancementCalculator;
