const ppfCalculator = {
    title: 'Kalkulator PPF',
    yearlyInvestment: 'Investasi Tahunan:',
    enterYearlyInvestment: 'Masukkan Investasi Tahunan',
    currentPPFInterestRate: 'Suku Bunga PPF Saat Ini (%):',
    enterPPFInterestRate: 'Masukkan Suku Bunga PPF',
    duration: 'Durasi (dalam tahun):',
    enterDuration: 'Masukkan Durasi Investasi',
    particulars: 'Rincian',
    amount: 'Jumlah',
    investedAmount: 'Jumlah Investasi',
    totalInterest: 'Total Bunga',
    ppfMaturity: 'Jatuh Tempo PPF',
};
export default ppfCalculator;
// Compare this snippet from src/pages/landings/resources/calculator/GratuityCalculator.jsx:
