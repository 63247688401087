const homePage = {
    heroHeader: 'Dorong keunggulan penjualan dan efisiensi operasional melalui',
    heroHeaderTypist: ['Manajemen Kehadiran', 'Pemantauan Pelacakan Lapangan', 'CRM'],
    heroHeaderSubText:
        'Kehadiran berbasis lokasi secara real-time, Pelacakan Langsung, Manajemen Tugas dan Pesanan, Wawasan Data dan layanan API',
    moreProductivityLessExpenses: 'Lebih Banyak Produktivitas, Lebih Sedikit Biaya',
    keyFeatures: [
        {
            title: 'Lens',
            description:
                'Clock-in dan clock-out dengan Facial Attendance tanpa kontak dengan Bantuan AI. Tandai kehadiran menggunakan kamera ponsel Anda.',
        },
        {
            title: 'Aplikasi Web',
            description:
                'Dasbor yang ramah pengguna, tim HR dan manajer dapat dengan mudah mengakses laporan terperinci, mengelola catatan karyawan, melacak kinerja, dan memeriksa penggajian.',
        },
        {
            title: 'Kontrol Berdasarkan Hierarki',
            description:
                'Sistem kontrol berbasis hierarki untuk mengakomodasi berbagai tingkat organisasi termasuk administratif, manajer HR, pengawas, dan karyawan.',
        },
        {
            title: 'Optimisasi Rute',
            description:
                'Tingkatkan efisiensi dan kurangi biaya operasional dengan perhitungan rute dinamis, optimisasi multi-titik, estimasi biaya, dan pembaruan waktu nyata.',
        },
    ],
    features: 'Fitur',
    ourFeatures:
        'Fitur kami sederhana, kuat, dan mudah diterapkan di berbagai industri untuk meningkatkan produktivitas organisasi.',
    ourFeaturesObject: [
        {
            title: 'Pemantauan Kehadiran',
            description: 'Tandai kehadiran dengan lokasi & lacak jam kerja',
        },
        {
            title: 'Manajemen Pengeluaran',
            description: 'Pengeluaran dilacak dan dicatat dengan cara yang akurat',
        },
        {
            title: 'Pelacakan Tenaga Kerja',
            description: 'Memantau dan mencatat aktivitas karyawan, produktivitas',
        },
        {
            title: 'Manajemen Penggajian',
            description: 'Optimalkan pemrosesan penggajian dan pembayaran yang akurat',
        },
        {
            title: 'Manajemen Pesanan',
            description: 'Proses pengelolaan pesanan/penarikan melalui berbagai strategi yang dapat disesuaikan',
        },
        {
            title: 'Optimisasi Rute',
            description: 'Menemukan jalur yang paling efisien untuk menghemat waktu, bahan bakar, dan sumber daya.',
        },
    ],
    whatWeDoQuestion: 'Apa yang Dilakukan TraceForce?',
    whatWeDoCards: [
        {
            title: 'Analisis Aktivitas Karyawan secara Real-Time',
            description: 'Dapatkan analisis mendetail tentang aktivitas tim secara harian atau per jam.',
        },
        {
            title: 'Kehadiran & Laporan Check-in Berbasis GeoFence',
            description: 'Tingkatkan efisiensi tim dengan manajemen kehadiran & cuti yang standar operatif.',
        },
        {
            title: 'Kelola Pesanan/Penarikan secara Efektif',
            description:
                'Kurangi kesalahan sambil meningkatkan produktivitas, kepuasan pelanggan dengan strategi pesanan cerdas dan penarikan tepat waktu.',
        },
        {
            title: 'Manajemen Tugas dari Awal hingga Akhir',
            description:
                'Sederhanakan perencanaan proyek, tugaskan tugas dengan mudah, dan tingkatkan kolaborasi waktu nyata di antara anggota tim Anda.',
        },
        {
            title: 'Wawasan Berdasarkan Laporan Otomatis',
            description: 'Visualisasikan keterlibatan karyawan dan lakukan analisis tim yang sempurna',
        },
        {
            title: 'Kelola Pengeluaran Berdasarkan Tunjangan',
            description:
                'Kelola sistem HR untuk mendistribusikan slip gaji secara online sesuai catatan di lembar kehadiran.',
        },
    ],
    whatWeDoList: [
        'Log Kehadiran',
        'Pelacak Lokasi Geo',
        'Penyimpanan Awan',
        'Peringatan dan Pemberitahuan',
        'Manajemen Proyek',
        'Manajemen Corong Penjualan',
    ],
    featureTitle: 'Fitur',
    featureSubTitle:
        'Fitur kami sederhana, kuat, dan mudah diterapkan di berbagai industri untuk meningkatkan produktivitas organisasi.',
    featureCards: [
        {
            title: 'Pemantauan Kehadiran',
            description: 'Tandai kehadiran dengan lokasi & lacak jam kerja',
        },
        {
            title: 'Manajemen Pengeluaran',
            description: 'Pengeluaran dilacak dan dicatat dengan cara yang akurat',
        },
        {
            title: 'Pelacakan Tenaga Kerja',
            description: 'Memantau dan mencatat aktivitas karyawan, produktivitas',
        },
        {
            title: 'Analitik Penjualan Lapangan',
            description: 'Kumpulkan data penjualan dan ukur kinerja penjualan',
        },
        {
            title: 'Manajemen Pesanan',
            description: 'Proses pengelolaan pesanan/penarikan melalui berbagai strategi yang dapat disesuaikan',
        },
        {
            title: 'Pelaporan Langsung',
            description: 'Ketahui di mana karyawan lapangan Anda berada dan pekerjaan apa yang mereka lakukan',
        },
    ],
    integrationTitle: 'INDUSTRI',
    integrationSubTitle: 'Menghubungkan Manajemen Tenaga Kerja dan Produktivitas untuk Semua Industri',
    orderManagementSubtitle: 'Lebih dari sekadar sistem manajemen pesanan',
    orderManagementTitle:
        'Otomatisasi, lacak, dan kirim dengan manajemen pesanan dan penarikan yang tepat untuk tim penjualan Anda.',
    orderManagementAccordian: [
        {
            title: 'Entri Pesanan Elektronik',
            points: [
                'Pelacakan status pesanan secara waktu nyata dari penempatan hingga pengiriman.',
                'Digitalkan proses dengan menangkap pesanan dan mengurangi kesalahan manual.',
            ],
        },
        {
            title: 'Pembuatan Faktur Otomatis',
            points: [
                'Hasilkan faktur secara otomatis berdasarkan detail pesanan.',
                'Kirim faktur secara elektronik untuk pemrosesan dan pembayaran yang lebih cepat.',
                'Verifikasi pembayaran berdasarkan OTP terhadap faktur.',
            ],
        },
        {
            title: 'Target Penjualan',
            points: [
                'Lacak kemajuan terhadap target dengan laporan dan analitik terperinci.',
                'Tetapkan dan pantau target penjualan untuk tim berdasarkan peran dan tanggung jawab.',
            ],
        },
        {
            title: 'Pelacakan Inventaris Waktu Nyata',
            points: [
                'Pertahankan hitungan stok yang akurat setiap kali pesanan dibuat dan diselesaikan.',
                'Tetapkan titik pemesanan ulang untuk secara otomatis menghasilkan pesanan pembelian pada stok rendah.',
            ],
        },
        {
            title: 'Distribusi Pesanan',
            points: [
                'Pantau pergerakan barang dari gudang ke pelanggan, memastikan akuntabilitas dan transparansi.',
                'Optimalkan proses distribusi antara tim penjualan berdasarkan prioritas dan lokasi.',
                'Optimisasi rute berbasis AI untuk pengiriman lebih cepat dan penghematan biaya.',
            ],
        },
    ],
    whyChooseTraceforce: 'Mengapa Memilih TraceForce',
    whyChooseTraceforceSubtitle:
        'Revolusi manajemen Sumber Daya Manusia dengan mengotomatiskan tugas administratif, menyederhanakan proses, dan meningkatkan keterlibatan karyawan.',
    whyChooseTraceForceList: [
        'Peningkatan Produktivitas',
        'Efisiensi Waktu',
        'Klaim dengan Presisi',
        'Pemantauan Waktu Nyata',
        'Responsif Sepenuhnya',
        'Optimisasi Berbasis AI',
        'Kustomisasi Berdasarkan Kriteria',
        'Dukungan Banyak Cabang',
        'Layanan Dukungan 24x7',
    ],
    calculatorTitle: 'Kalkulator',
    calculatorSubtitle:
        'Fitur kami sederhana, kuat, dan mudah diterapkan di berbagai industri untuk meningkatkan produktivitas organisasi.',
    workingHours: 'Jam Kerja',
    uncountedHours: 'Jam yang Belum Terhitung',
    dailySavings: 'Penghematan Harian',
    monthlySavings: 'Penghematan Bulanan',
    productivityGrowth: 'Pertumbuhan Produktivitas',
    numberOfEmployees: 'Jumlah Karyawan',
    faqs: 'FAQ',
    frequentlyAskedQuestions: 'Pertanyaan yang Sering Diajukan',
    faqLine: 'Berikut adalah beberapa jenis pertanyaan dasar dari pelanggan kami',
    faqAccordian: [
        {
            question: 'Bagaimana cara mendaftar perusahaan saya?',
            ans: 'Anda dapat mendaftar di TraceForce dalam 3 langkah mudah melalui aplikasi Seluler atau aplikasi Web kami. Cukup tambahkan nama, email, dan nomor GST Anda, dan Anda siap untuk memulai.',
        },
        {
            question: 'Apa saja cara berbeda untuk mengautentikasi Login saya?',
            ans: 'TraceForce menyediakan beberapa opsi Clock In. Misalnya, kami memiliki opsi login mobile default, opsi berbasis selfie, dan yang terakhir tetapi tidak kalah pentingnya, autentikasi wajah berbasis AI yang paling aman.',
        },
        {
            question: 'Bagaimana cara menandai Kehadiran?',
            ans: 'Pengguna dapat menandai kehadiran hanya ketika orang tersebut berada di dalam Geofence yang dialokasikan untuknya. Selain itu, untuk mencegah proksi, kami memiliki berbagai metode autentikasi yang diterapkan.',
        },
        {
            question:
                'Bisakah kita memalsukan Lokasi atau mengubah waktu untuk Tugas yang selesai atau kehadiran yang ditandai?',
            ans: 'TraceForce mendeteksi aktivitas spam atau tidak sah dan akan menonaktifkan aplikasi serta memberi tahu pengguna.',
        },
        {
            question: 'Apakah TraceForce mendukung karyawan remote?',
            ans: 'TraceForce mendukung karyawan kontrak berdasarkan jam dan oleh karena itu menghitung penggajian berdasarkan jam tetap atau shift jam fleksibel.',
        },
        {
            question: 'Bisakah saya melacak tenaga kerja saya melalui ponsel?',
            ans: 'TraceForce membantu Anda dalam melacak dan mengelola tenaga kerja Anda melalui ponsel dan web dalam format Timeline. Dengan demikian, memberi Anda detail komprehensif tentang tampilan hari tersebut.',
        },
        {
            question: 'Apa layanan lain yang ditawarkan TraceForce selain dari HRMS?',
            ans: 'TraceForce menawarkan daftar layanan yang sangat komprehensif untuk mempermudah pekerjaan manajer atau pemilik bisnis dan membantu mereka dengan hanya pemberitahuan yang diperlukan.',
        },
    ],

    referAndGet: 'Referensikan dan Dapatkan',
    referAndGetH2: 'Dapatkan Diskon 8% untuk Paket Tahunan TraceForce!',
    referAndGetp:
        'Referensikan teman atau klien ke TraceForce dan dapatkan diskon 8% untuk paket tahunan Anda setelah mereka bergabung dengan kami! Bagikan manfaatnya dan hemat bersama!',
};
export default homePage;
