const navbar = {
    calculator: 'कैलकुलेटर',
    resources: 'संसाधन',
    integration: 'एकीकरण',
    industries: 'उद्योग',
    pharmaceuticals: 'फार्मास्यूटिकल्स',
    fieldSales: 'फील्ड सेल्स',
    fmcg: 'एफएमसीजी',
    service: 'सेवा',
    fieldOperations: 'फील्ड ऑपरेशंस',
    logisticsAndTransportation: 'लॉजिस्टिक्स और ट्रांसपोर्टेशन',
    education: 'शिक्षा',
    products: 'उत्पाद',
    pricing: 'मूल्य निर्धारण',
    attendanceManagement: 'उपस्थिति प्रबंधन',
    fieldForceManagement: 'क्षेत्र बल प्रबंधन',
    company: 'कंपनी',
    ourStory: 'हमारी कहानी',
    joinUs: 'हमसे जुड़ें',
    contactUs: 'संपर्क करें',
    integration: 'एकीकरण',
    becomeAPartner: 'साझेदार बनें',
    blog: 'ब्लॉग',
};
export default navbar;
