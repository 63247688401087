const salaryCalculator = {
    enterAnnualCTC: 'Insira o CTC Anual',
    enterAnnualCTCPlaceholder: 'Insira o seu CTC anual e pressione Enter',
    component: 'Componente',
    monthly: 'Mensal',
    annual: 'Anual',
    grossSalary: 'Salário Bruto',
    basicSalary: 'Salário Básico',
    hra: 'Auxílio Moradia (HRA)',
    specialAllowance: 'Abono Especial',
    employeeContribution: 'Contribuição do Funcionário',
    epf: 'Fundo de Previdência do Funcionário (EPF)',
    labourWelfareFund: 'Fundo de Bem-Estar do Trabalhador (LWF)',
    esic: 'Corporação de Seguro Social dos Funcionários (ESIC)',
    employerContribution: 'Contribuição do Empregador',
    takeHomeSalary: 'Salário Líquido',
};
export default salaryCalculator;
