const fnfCalculator = {
    title: 'ફુલ અને ફાઈનલ સેટલમેન્ટ કેલ્ક્યુલેટર',
    basicSalary: 'મૂળ પગાર (આવશ્યક)',
    earnedLeaves: 'પ્રાપ્ત રજાઓ (આવશ્યક)',
    paydaysInMonth: 'પ્રતિ મહિનો પે-ડે (આવશ્યક)',
    noticePeriodSalary: 'નોટિસ પિરીયડ પગાર (વૈકલ્પિક)',
    gratuityPay: 'ગ્રેચ્યુઇટી પે (વૈકલ્પિક)',
    bonus: 'બોનસ (વૈકલ્પિક)',
    deductions: 'કટોકટી (EPF, ESI, કર) (વૈકલ્પિક)',
    totalFinalSettlementAmount: 'કુલ અંતિમ સેટલમેન્ટ રકમ',
    enterBasicSalary: 'મૂળ પગાર દાખલ કરો',
    enterEarnedLeaves: 'પ્રાપ્ત રજાઓ દાખલ કરો',
    enterNumberOfPaydaysInMonth: 'પ્રતિ મહિનો પે-ડે ની સંખ્યા દાખલ કરો',
    enterNoticePeriodSalary: 'નોટિસ પિરીયડ પગાર દાખલ કરો',
    enterGratuityPay: 'ગ્રેચ્યુઇટી પે દાખલ કરો',
    enterBonusAmount: 'બોનસ રકમ દાખલ કરો',
    enterTotalDeductions: 'કુલ કટોકટી દાખલ કરો',
};
export default fnfCalculator;
